import React, { FC, useContext } from "react";
import { isThisInstagram } from "../../../helpers/mainPage";
import { MainContext } from "../../pages/MainPage/context";
import { InstagramContext } from "../../pages/Instagram/context";
import { currencySymbolConversion } from "../../pages/MainPage/helpers/MainPage.helpers";
import {
  PriceBarContainer,
  TitleBar,
  PriceWrapper,
  Price,
  SavePrice,
} from "./PriceBar.style";

interface PriceBarProps {
  member: any;
  dealPrice: string | number;
}

const PriceBar: FC<PriceBarProps> = ({ member, dealPrice }) => {
  const isInstagram = isThisInstagram();
  const MContext = useContext(isInstagram ? InstagramContext : MainContext);
  const savedprice = parseInt(member?.price) - parseInt(dealPrice as string);

  return (
    <PriceBarContainer>
      <TitleBar> {member?.title} </TitleBar>
      <PriceWrapper>
        Current Price:
        <Price isInstagram={isInstagram}>
          {currencySymbolConversion(
            member?.price,
            MContext?.currencyCode ?? "NGN"
          )}
        </Price>
        <SavePrice isInstagram={isInstagram}>
          You save
          <span>
            {currencySymbolConversion(
              savedprice,
              MContext?.currencyCode ?? "NGN"
            )}
          </span>
        </SavePrice>
      </PriceWrapper>
    </PriceBarContainer>
  );
};

export default PriceBar;
