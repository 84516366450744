import React from "react";
import Footer from "../../atoms/Footer";
import DealsRender from "../../organisms/DealsRender";
import DealsSelector from "../../organisms/DealsSelector";
import MainProvider from "./context";
import { MainPageWrapper } from "./MainPage.style";

const MainPage = () => {
  return (
    <MainProvider>
      <MainPageWrapper>
        <DealsSelector />
        <DealsRender />
        <Footer />
      </MainPageWrapper>
    </MainProvider>
  );
};

export default MainPage;
