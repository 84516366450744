import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { GETDEALS } from "../schemas/schema";
import { transformData } from "./helpers/useGetDeals.helpers";

interface ResponseType {
  deals?: any[] | undefined;
  loading: boolean;
  error?: any;
}

const useGetDeals = (productType: string, refresh: boolean): ResponseType => {
  const [getDeals, { loading, error, data }] = useLazyQuery(GETDEALS, {
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (productType && refresh) {
      getDeals({
        variables: {
          productType,
        },
      });
    }
  }, [getDeals, productType, refresh]);

  if (!refresh) {
    return { loading, deals: transformData(data) };
  }

  if (error) {
    return {
      deals: undefined,
      loading,
    };
  }

  if (data) {
    return {
      deals: transformData(data),
      loading,
    };
  } else {
    return {
      deals: [],
      loading,
    };
  }
};

export default useGetDeals;
