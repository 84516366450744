import React, { useContext } from "react";
import { InstagramContext } from "../../pages/Instagram/context";

import { Selector, SelectWrapper } from "./DealsSelector.style";

const DealsSelectorI = () => {
  const MContext = useContext(InstagramContext);
  const availableDeals = [
    {
      value: "Sterling Silver Engagement Ring",
      label: "Sterling Silver Engagement Rings",
    },
    {
      value: "Sterling Silver Bridal Set",
      label: "Sterling Silver Bridal Sets",
    },
    {
      value: "Sterling Silver Wedding Band",
      label: "Sterling Silver Wedding Bands",
    },
    { value: "Gold Engagement Ring", label: "Gold Engagement Rings" },
    { value: "Gold Bridal Set", label: "Gold Bridal Sets" },
    { value: "Gold Wedding Bands", label: "Gold Wedding Bands" },
    {
      value: "Gold Complete Sets",
      label: "Gold Complete Wedding Sets",
    },
  ];

  const availableCurrencies = [
    {
      value: "USD",
      label: "USD",
    },

    {
      value: "NGN",
      label: "NGN",
    },
    {
      value: "GHS",
      label: "GHS",
    },
  ];

  const handleChange = (value: string) => {
    MContext?.setProductTypeSelected(value);
  };
  const handleChangeC = (value: string) => {
    MContext?.setCurrencyCode(value);
  };

  return (
    <SelectWrapper>
      <Selector
        defaultValue={MContext?.productTypeSelected}
        value={MContext?.productTypeSelected}
        style={{ width: 250 }}
        onChange={(e) => handleChange(e as string)}
        options={availableDeals}
      />
      <Selector
        defaultValue={MContext?.currencyCode}
        value={MContext?.currencyCode}
        style={{ width: 80 }}
        onChange={(e) => handleChangeC(e as string)}
        options={availableCurrencies}
      />
    </SelectWrapper>
  );
};

export default DealsSelectorI;
