import styled from "styled-components";
import { Carousel } from "antd";
import { device } from "../../../devices";
import { colors } from "../../../theme";

export const DealContainer = styled.div<{ isInstagram?: boolean }>`
  margin-top: 20px;
  box-sizing: border-box;
  /* background-color: rgb(255, 255, 255); */
  background-color: ${({ isInstagram }) =>
    isInstagram ? "#fff" : colors.background1};
  border-radius: 16px;
  /* padding: 0 10px; */
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px 0px,
    rgb(0 0 0 / 12%) 0px 4px 2px -2px, rgb(0 0 0 / 16%) 0px 4px 8px -1px;

  width: 320px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-bottom: 20px;
  @media ${device.tablet} {
    width: auto;
  }

  ${({ isInstagram }) =>
    isInstagram &&
    `
  
  box-shadow:none
  `}
`;

export const Line1 = styled.div<{ isInstagram?: boolean }>`
  /* position: absolute; */
  top: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 12px;
  background-color: ${({ isInstagram }) =>
    isInstagram ? colors.background2 : colors.background2};
  margin-bottom: 20px;
`;
export const DML = styled.div`
  position: relative;
  /* margin-bottom: 16px; */
`;
export const DMD = styled.div`
  /* position: absolute; */
  right: 20px;
  top: 42px;
`;

export const PriceFlash = styled.div<{ isInsta?: boolean }>`
  color: ${colors.btn};
  font-size: ${({ isInsta }) => (isInsta ? "40px" : "20px")};
  font-weight: bolder;
  text-align: right;
  padding-right: 15px;
  padding-bottom: 10px;
`;

export const DealWrapper = styled.div<{ isInstagram?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  ${({ isInstagram }) =>
    isInstagram &&
    `
  margin-top:40px;
  `}
`;

export const Section = styled.div<{ isInstagram?: boolean }>`
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px 0px,
    rgb(0 0 0 / 12%) 0px 4px 2px -2px, rgb(0 0 0 / 16%) 0px 4px 8px -1px;

  &:hover {
    box-shadow: rgb(220, 179, 63, 20%) 0px 0px 12px 10px,
      rgb(0 0 0 / 12%) 0px 4px 2px -2px, rgb(0 0 0 / 16%) 0px 4px 8px -1px;
  }
  ${({ isInstagram }) =>
    isInstagram &&
    `
  border: 1px solid black
  `}
`;

export const OrderCTAWrapper = styled.div<{ isInstagram?: boolean }>`
  margin-top: 20px;
  text-align: right;
  margin-right: 10px;

  ${({ isInstagram }) =>
    isInstagram &&
    `
  text-align:center;
  margin-top: 50px;
  `}
`;

export const CTA = styled.div<{ isInstagram?: boolean }>`
  cursor: pointer;
  background: ${colors.btn};
  display: inline-flex;
  padding: 10px 50px;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;

  &:hover {
    background: darkred;
  }

  ${({ isInstagram }) =>
    isInstagram &&
    `
    padding: 25px 50px;
    font-size: 34px;
  `}
`;

export const MobileCarousel = styled(Carousel)`
  button {
    background: grey !important;
  }
`;

export const ExpiresWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
`;
