import styled from "styled-components";
import { colors } from "../../../theme";

export const PriceBarContainer = styled.div``;
export const TitleBar = styled.div`
  color: #000;
  margin-top: 6px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 10px;
  height: 40px;
  overflow-y: auto;
`;
export const PriceWrapper = styled.div`
  background: #f1f2f6;
  padding: 10px;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
`;

export const SavePrice = styled.div<{ isInstagram?: boolean }>`
  margin-top: 8px;
  font-size: 12px;
  color: grey;
  span {
    /* font-weight: bold;
    color: ${colors.green}; */
    margin-left: 5px;

    color: white;
    background: black;
    padding: 5px 10px;
    border-radius: 10px;
  }

  ${({ isInstagram }) =>
    isInstagram &&
    `
  font-size: 25px;
  // background: ${colors.green}  ;
  color: #000  ;
  text-align:center;
  padding: 10px 10px;

  span {
    color: white;
    background:black;
    padding: 5px 10px ;
    border-radius: 10px;
  }
  `}
`;

export const SavePrice1 = styled(SavePrice)`
  background-color: ${colors.green};
  color: #fff;
`;

export const Price = styled.span<{ isInstagram?: boolean }>`
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  /* color: ${colors.btn}; */

  ${({ isInstagram }) =>
    isInstagram &&
    `
  font-size: 20px;
  font-weight: normal;
  `}
`;
