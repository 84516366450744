import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import {
  PaymentDoneContainer,
  Header,
  ConfirmedText,
  OtherText,
  CustomerDetails,
  ImgWrapper,
  Product,
  PlacedOn,
  OrderSummaryWrapper,
  SummaryHeader,
  SummaryDetailsWrapper,
  TotalText,
  Price,
  BackButtonWrapper,
  OrderNumber,
  Line,
  CustomerText,
  PlacedOn2,
  ConfirmedWrapper,
} from "./PaymentDone.style";
import { numberWithCommas2 } from "../MainPage/helpers/MainPage.helpers";
import Buttons from "../../atoms/Buttons/Buttons";
import { LoadingWrapper } from "../../organisms/DealsRender/DealsRender.style";
import Loading from "../../atoms/Loading/Loading";
import ConfirmedIcon from "./Svgs/Confirmed";
import Footer from "../../atoms/Footer";
import { SERVER_URL } from "../../../helpers/config";

const PaymentDone = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState("");
  const [data, setData] = useState<any>();

  const reference = searchParams.get("reference");
  const source = searchParams.get("source");

  const verifyActionPayStack = useCallback(async () => {
    await Axios.get(
      `${SERVER_URL()}/verifyPaystackPayment?reference=${reference}`
    ).then((response) => {
      if (response.data.data.data.status === "success") {
        setStatus("success");
        setData(response.data.data.data);
      } else {
        window.open("/", "_self");
      }
    });
  }, [reference]);

  const verifyActionStripe = useCallback(async () => {
    await Axios.get(
      `${SERVER_URL()}/verifyStripePayment?reference=${reference}`
    ).then((response) => {
      if (response.data.data.payment_status === "paid") {
        setStatus("success");
        setData(response.data.data);
      } else {
        window.open("/", "_self");
      }
    });
  }, [reference]);

  useEffect(() => {
    if (source === "paystack") {
      verifyActionPayStack();
    }
    if (source === "stripe") {
      verifyActionStripe();
    }
  }, [verifyActionPayStack, verifyActionStripe, source]);

  const {
    first_name,
    last_name,
    title,
    Address1,
    Address2,
    City,
    State,
    Country,
    image,
    id,
  } = data?.metadata ?? {};
  const { currency, amount, amount_total } = data ?? {};
  const paidDate = new Date(data?.paidAt ?? data?.created * 1000);
  const month = paidDate.toLocaleString("default", { month: "short" });
  const day = paidDate.getDate();
  const year = paidDate.getFullYear();

  if (status === "success") {
    return (
      <>
        <PaymentDoneContainer>
          <Header> DELPHI METALS DEALS</Header>
          <ConfirmedText>
            <ConfirmedWrapper>
              <ConfirmedIcon width="24" height="24" />
            </ConfirmedWrapper>
            Your Order is Confirmed
          </ConfirmedText>
          <OtherText>
            Hi {first_name}, we're writing to let you know that we're getting
            your order
            <OrderNumber>{` #${id} `}</OrderNumber>
            ready. A member of our team would try to contact you shortly.
          </OtherText>
          <Line />
          <CustomerDetails>
            <CustomerText> {`${first_name} ${last_name}`} </CustomerText>
            <CustomerText>{Address1} </CustomerText>
            <CustomerText>{Address2} </CustomerText>
            <CustomerText>{`${City}, ${State}`} </CustomerText>
            <CustomerText>{Country} </CustomerText>
          </CustomerDetails>
          <ImgWrapper>
            <img src={image} alt={title} width={180} />
          </ImgWrapper>
          <Product> {title} </Product>
          <Line />
          <PlacedOn> Placed On </PlacedOn>
          <PlacedOn2> {`${month} ${day}, ${year}`} </PlacedOn2>
          <OrderSummaryWrapper>
            <SummaryHeader> Order Summary</SummaryHeader>
            <SummaryDetailsWrapper>
              <TotalText> TOTAL</TotalText>
              <Price>
                {`${currency.toUpperCase()}${numberWithCommas2(
                  amount ?? amount_total
                )}`}
              </Price>
            </SummaryDetailsWrapper>
          </OrderSummaryWrapper>

          <BackButtonWrapper>
            <Buttons
              text="Back to Deals"
              onClick={() => navigate("/")}
              type="primary"
            />
          </BackButtonWrapper>

          <Footer />
        </PaymentDoneContainer>
      </>
    );
  }

  return (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  );
};

export default PaymentDone;
