import { useEffect } from "react";

import { notification } from "antd";

const useError = (error: string) => {
  const openNotification = (error: string) => {
    notification.open({
      message: "Error",
      type: "error",
      placement: "bottomLeft",
      duration: null,
      description: error,
      onClick: () => {
        // console.log("Notification Clicked!");
      },
    });
  };

  useEffect(() => {
    if (error) {
      openNotification(error);
    }
  }, [error]);
};

export default useError;
