import styled from "styled-components";

export const OrderContainer = styled.div`
  margin-top: 20px;
`;

export const StepsWrapper = styled.div``;
export const PickItem = styled.div``;
export const Address = styled.div``;
export const Payment = styled.div``;
export const Complete = styled.div``;
