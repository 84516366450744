import { gql } from "@apollo/client";

export const GETDEALS = gql`
  query GetDeals($productType: String) {
    getDeals(productType: $productType) {
      dealId
      handle
      itemId
      featuredImageUrl
      currencyCode
      onlineStoreUrl
      price
      productType
      title
    }
    getDealAssociations(productType: $productType) {
      dealId
      dealMembers
      productType
      dealPrice
    }
  }
`;

export const SAVE_RESERVED_DEALS = gql`
  mutation SaveReservedDeal($params: DealReservedArgType) {
    saveReservedDeal(params: $params) {
      id
    }
  }
`;
