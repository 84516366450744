import { Pagination } from "antd";
import React, { useEffect, useContext, useState, useMemo } from "react";
import useGetDeals from "../../../hooks/useGetDeals";
import Loading from "../../atoms/Loading/Loading";
import DealsCard from "../../molecules/DealsCard";
import { MainContext } from "../../pages/MainPage/context";
import { LoadingWrapper, PaginationWrapper } from "./DealsRender.style";

const DealsRender = () => {
  const MContext = useContext(MainContext);
  const [totalPageSize, setTotalPageSize] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [refresh, setRefresh] = useState(false);

  const { deals, loading } = useGetDeals(
    MContext?.productTypeSelected!,
    refresh
  );

  const filteredDeals = useMemo(() => {
    return deals!?.filter((res) => res.dealMembers.length === 3) ?? [];
  }, [deals]);

  const getRenderedDeals = (deals: any[], currentPage: number) => {
    const max = currentPage * 5 - 1;
    const min = currentPage * 5 - 5;
    const result = [];

    for (let i = min; i <= max; i++) {
      result.push(deals[i]);
    }
    return result;
  };

  useEffect(() => {
    if (filteredDeals && filteredDeals.length) {
      setRefresh(false);
      setTotalPageSize(Math.ceil(filteredDeals.length / 5) * 10);
    }
  }, [filteredDeals]);

  useEffect(() => {
    if (currentPage > 1) {
      setCurrentPage(1);
    }
    setRefresh(true);
  }, [MContext?.productTypeSelected]);

  const renderedD = getRenderedDeals([...filteredDeals!], currentPage!);

  if (loading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  if (renderedD && renderedD.length) {
    return (
      <>
        {renderedD.map((deal, i) => {
          if (deal?.dealMembers.length === 3) {
            return <DealsCard deal={deal} key={`dealsRendered-${i}aosin3`} />;
          }
          return null;
        })}
        <PaginationWrapper>
          <Pagination
            defaultCurrent={currentPage}
            current={currentPage}
            total={totalPageSize}
            onChange={(e) => {
              setCurrentPage(e);
              window.scrollTo(0, 0);
            }}
          />
        </PaginationWrapper>
      </>
    );
  }

  return <div> No data present</div>;
};

export default DealsRender;
