import React, { FC } from "react";
import { ButtonX } from "./Buttons.style";

interface ButtonsProps {
  text: string;
  type: any;
  onClick: () => void;
  disabled?: boolean;
}

const Buttons: FC<ButtonsProps> = ({ text, type, onClick, disabled }) => {
  return (
    <ButtonX type={type} onClick={onClick} disabled={disabled}>
      {text}
    </ButtonX>
  );
};

export default Buttons;
