export const transformId = (id: string | undefined) => {
  switch (id) {
    case "gold-engagement-ring":
      return "Gold Engagement Ring";
    case "sterling-silver-bridal-set":
      return "Sterling Silver Bridal Set";
    case "sterling-silver-engagement-ring":
      return "Sterling Silver Engagement Ring";
    case "gold-bridal-set":
      return "Gold Bridal Set";
    case "gold-wedding-bands":
      return "Gold Wedding Bands";
    case "gold-complete-sets":
      return "Gold Complete Sets";
    default:
      return "Sterling Silver Engagement Ring";
  }
};

export const isThisInstagram = () => {
  const url = window.location.href;

  return url.includes("instagram");
};

export function getSundayOfCurrentWeek() {
  const today = new Date();
  const first = today.getDate() - today.getDay() + 1;
  const last = first + 6;

  const sunday = new Date(today.setDate(last));

  sunday.setHours(23, 59, 59);

  return sunday.toDateString();
}
