import styled from "styled-components";
import { Checkbox, Popconfirm, Select } from "antd";
import { device } from "../../../devices";

export const Stage1Wrapper = styled.div`
  margin-top: 40px;

  margin-bottom: 20px;
`;

export const SelectorImgContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media ${device.tablet} {
    flex-direction: unset;
    justify-content: space-between;
    align-items: center;
  }
`;

export const SelectorDescriptor = styled.div`
  color: grey;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const RingSizeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectorWrapper = styled.div``;
export const ImageWrapper = styled.div`
  text-align: center;
`;

export const Selector = styled(Select)`
  .ant-select-arrow {
    color: #004ab8 !important;
  }
`;

export const CTAWrapper = styled.div`
  text-align: right;
  margin-top: 40px;
`;

export const ToolTipSpan = styled.span`
  margin-left: 10px;
  cursor: pointer;
`;

export const TOCWrapper = styled.div`
  margin-top: 20px;
  position: relative;
  top: 20px;
`;

export const Tickbox = styled(Checkbox)``;

export const TermsText = styled.div`
  margin-left: 10px;
  cursor: pointer;
`;

export const PopConfirmWrapper = styled.div``;

export const PopConfirm = styled(Popconfirm)``;

export const CheckContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const CheckWrapper = styled.div``;

export const TOCContainer = styled.div`
  width: 320px;
  @media ${device.tablet} {
    width: 600px;
  }
`;

export const TItemWrapper = styled.div`
  margin-bottom: 10px;
`;

export const Subject = styled.span`
  font-weight: bold;
`;
export const Contents = styled.span``;
