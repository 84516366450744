import React, { FC } from "react";
import { isThisInstagram } from "../../../helpers/mainPage";
import PriceBar from "../PriceBar";
import {
  ImageWrapper,
  PriceFooter,
  SectionCardWrapper,
} from "./SectionCard.style";

interface SectionCardProps {
  member: any;
  dealPrice: string | number;
}
const SectionCard: FC<SectionCardProps> = ({ member, dealPrice }) => {
  const isInstagram = isThisInstagram();
  return (
    <SectionCardWrapper>
      <ImageWrapper isInstagram={isInstagram}>
        <img
          src={member?.featuredImageUrl}
          alt={member?.title}
          width={isInstagram ? 340 : 250}
        />
      </ImageWrapper>
      <PriceFooter isInstagram={isInstagram}>
        <PriceBar member={member} dealPrice={dealPrice} />
      </PriceFooter>
    </SectionCardWrapper>
  );
};

export default SectionCard;
