import styled from "styled-components";
import { Select } from "antd";
import { device } from "../../../devices";

export const SelectWrapper = styled.div`
  display: block;

  @media ${device.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

export const Selector = styled(Select)`
  .ant-select-arrow {
    color: #004ab8 !important;
  }
  :last-child {
    margin-top: 10px;
    @media ${device.tablet} {
      margin-top: 0;
    }
  }
`;
