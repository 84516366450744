import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  PaymentDoneContainer,
  Header,
  ConfirmedText,
  OtherText,
  CustomerDetails,
  ImgWrapper,
  Product,
  PlacedOn,
  OrderSummaryWrapper,
  SummaryHeader,
  SummaryDetailsWrapper,
  TotalText,
  Price,
  BackButtonWrapper,
  OrderNumber,
  Line,
  CustomerText,
  PlacedOn2,
  ConfirmedWrapper,
} from "../PaymentDone/PaymentDone.style";
import { currencySymbolConversion } from "../MainPage/helpers/MainPage.helpers";
import Buttons from "../../atoms/Buttons/Buttons";
import { PriceWrapper } from "./OrderInStore.style";
import useSaveReservedDeals from "../../../hooks/useSaveReservedDeals";
import { useEffect, useState } from "react";
import ConfirmedIcon from "../PaymentDone/Svgs/Confirmed";
import Footer from "../../atoms/Footer";

const OrderInStore = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [id] = useState<number>(new Date().valueOf());
  const [fetch, setFetch] = useState<boolean>(true);
  const { details, currencyCode: currency, dealPrice } = state ?? {};
  const {
    dealId,
    itemId,
    title,
    image,
    Address1,
    Address2,
    State,
    City,
    Email,
    ringSize,
    Country,
    itemPrice,
    onlineStoreUrl,
  } = details ?? {};

  const date = new Date().toString();
  const getLastDate = () => {
    const curr = new Date();
    const lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
    return lastday.toString();
  };

  const params = {
    dealId,
    itemId,
    address1: Address1,
    address2: Address2,
    city: City,
    currencyCode: currency,
    dealPrice: dealPrice.toString(),
    email: Email,
    expiryDate: getLastDate(),
    fname: details["First Name"],
    lname: details["Last Name"],
    image,
    itemPrice,
    onlineStoreUrl,
    orderDate: date ?? "",
    phoneNumber: details["Phone Number"],
    reference: id?.toString() ?? "",
    ringSize: ringSize.toString(),
    state: State,
    title,
    status: "Ordering",
    Country,
    id: id.toString(),
  };

  const { id: saveId, loading, error } = useSaveReservedDeals(params, fetch);

  useEffect(() => {
    if (!loading) {
      setFetch(false);
    }
  }, [loading]);

  const orderDate = new Date(date!);
  const month = orderDate.toLocaleString("default", { month: "short" });
  const day = orderDate.getDate();
  const year = orderDate.getFullYear();
  return (
    <>
      <PaymentDoneContainer>
        <Header>
          <img
            src="https://cdn.shopify.com/s/files/1/0113/7367/4554/files/logo_trans2.png?v=1613723082"
            alt="Delphi Metals Logo"
            width={100}
          />
        </Header>
        <ConfirmedText>
          <ConfirmedWrapper>
            <ConfirmedIcon width="24" height="24" />
          </ConfirmedWrapper>
          Your Deal is Reserved
        </ConfirmedText>
        <OtherText>
          Hi {details["First Name"]}, we're writing to let you know that have
          reserved your deal
          <OrderNumber>{` #${id} `}</OrderNumber>. A member of our team would
          try to contact you shortly. This Deal expires {getLastDate()}
        </OtherText>
        <Line />
        <CustomerDetails>
          <CustomerText>
            {" "}
            {`${details["First Name"]} ${details["Last Name"]}`}{" "}
          </CustomerText>
          <CustomerText>{Address1} </CustomerText>
          <CustomerText>{Address2} </CustomerText>
          <CustomerText>{`${City}, ${State}`} </CustomerText>
          <CustomerText>{Country} </CustomerText>
        </CustomerDetails>
        <ImgWrapper>
          <img src={image} alt={title} width={180} />
        </ImgWrapper>
        <Product> {title} </Product>
        <PriceWrapper>
          {currencySymbolConversion(itemPrice, currency)}
        </PriceWrapper>
        <Product>Ring Size: {ringSize} </Product>
        <Line />
        <PlacedOn> Placed On </PlacedOn>
        <PlacedOn2> {`${month} ${day}, ${year}`} </PlacedOn2>
        <OrderSummaryWrapper>
          <SummaryHeader> Order Summary</SummaryHeader>
          <SummaryDetailsWrapper>
            <TotalText> TOTAL</TotalText>
            <Price>
              {" "}
              {`${currencySymbolConversion(dealPrice, currency!)} `}{" "}
            </Price>
          </SummaryDetailsWrapper>
        </OrderSummaryWrapper>

        <BackButtonWrapper>
          <Buttons
            text="Back to Deals"
            onClick={() => navigate("/")}
            type="primary"
          />
        </BackButtonWrapper>

        <Footer />
      </PaymentDoneContainer>
    </>
  );
};

export default OrderInStore;
