import React, {
  createContext,
  FC,
  useState,
  useEffect,
  useCallback,
} from "react";
import Axios from "axios";
import { resolveCurrencyCodes } from "../../../helpers/resolveCurrencyCodes";

interface InstagramProviderProps {
  children: React.ReactNode;
}

interface ContextType {
  deals: any[];
  setDeals: React.Dispatch<React.SetStateAction<any[]>>;
  associations: any[];
  setAssociations: React.Dispatch<React.SetStateAction<any[]>>;
  productTypeSelected: string;
  setProductTypeSelected: React.Dispatch<React.SetStateAction<string>>;
  country: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  currencyCode: string;
  setCurrencyCode: React.Dispatch<React.SetStateAction<string>>;
  dealPrice: number | undefined;
  setDealPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const InstagramContext = createContext<ContextType | null>(null);

const { Provider } = InstagramContext;

const InstagramProvider: FC<InstagramProviderProps> = ({ children }) => {
  const [productTypeSelected, setProductTypeSelected] = useState<string>(
    "Sterling Silver Engagement Ring"
  );
  const [deals, setDeals] = useState<any[]>([]);
  const [country, setCountry] = useState<string>("Nigeria");
  const [associations, setAssociations] = useState<any[]>([]);
  const [currencyCode, setCurrencyCode] = useState<string>("NGN");
  const [dealPrice, setDealPrice] = useState<number | undefined>();

  const fetchCountry = useCallback(async () => {
    const result = await Axios.get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountry(data.country_name);
        return data.country_name;
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  }, []);

  useEffect(() => {
    fetchCountry().then((res) => {
      setCurrencyCode(resolveCurrencyCodes(res));
    });
  }, [fetchCountry]);

  const value = {
    deals,
    setDeals,
    associations,
    setAssociations,
    productTypeSelected,
    setProductTypeSelected,
    currencyCode,
    setCurrencyCode,
    dealPrice,
    setDealPrice,
    country,
    setCountry,
  };
  return <Provider value={value}>{children}</Provider>;
};

export default InstagramProvider;
