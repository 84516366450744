import React, { FC, useState, createContext } from "react";
import { OrderContainer, StepsWrapper } from "./OrderModal.style";
import { useMediaQuery } from "react-responsive";
import { Steps } from "antd";
import Stage1 from "../Stage1";
import Stage2 from "../Stage2";
import PaymentStage from "../PaymentStage";

interface OrderModalProps {
  deal: any;
  onCancel: () => void;
}

interface OrderContextType {
  stage: number;
  setStage: React.Dispatch<React.SetStateAction<number>>;
  dealPicked: any;
  setDealPicked: React.Dispatch<any>;
  details: any;
  setDetails: React.Dispatch<any>;
  paymentChoice: any;
  setPaymentChoice: React.Dispatch<any>;
}

export const OrderContext = createContext<OrderContextType | null>(null);

const { Provider } = OrderContext;

const OrderModal: FC<OrderModalProps> = ({ deal, onCancel }) => {
  const { dealMembers } = deal ?? {};
  const [stage, setStage] = useState(0);
  const [dealPicked, setDealPicked] = useState<any>();
  const [details, setDetails] = useState<any | null>({});
  const [paymentChoice, setPaymentChoice] = useState<any>();

  const isMobile = useMediaQuery({
    query: "(min-width: 375px)",
  });

  const value = {
    stage,
    setStage,
    dealPicked,
    setDealPicked,
    details,
    setDetails,
    paymentChoice,
    setPaymentChoice,
  };

  return (
    <Provider value={value}>
      <OrderContainer>
        {!isMobile && (
          <StepsWrapper>
            <Steps
              size="small"
              current={stage}
              items={[
                {
                  title: "Select Item",
                },
                {
                  title: "Details",
                },
                {
                  title: "Payment",
                },
                {
                  title: "Done",
                },
              ]}
            />
          </StepsWrapper>
        )}
        {stage === 0 && (
          <div>
            <Stage1 dealMembers={dealMembers} onCancel={onCancel} />
          </div>
        )}
        {stage === 1 && (
          <div>
            <Stage2 onCancel={onCancel} />
          </div>
        )}
        {stage === 2 && (
          <div>
            <PaymentStage onCancel={onCancel} />
          </div>
        )}
        {stage === 3 && <div> stage 3</div>}
      </OrderContainer>
    </Provider>
  );
};

export default OrderModal;
