export const COUNTRIES_ = [
  {
    name: "Afghanistan",
    code: "AF",
    timezone: "Afghanistan Standard Time",
    utc: "UTC+04:30",
    mobileCode: "+93",
  },
  {
    name: "Åland Islands",
    code: "AX",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+358-18",
  },
  {
    name: "Albania",
    code: "AL",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+355",
  },
  {
    name: "Algeria",
    code: "DZ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+213",
  },
  {
    name: "American Samoa",
    code: "AS",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+1-684",
  },
  {
    name: "Andorra",
    code: "AD",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+376",
  },
  {
    name: "Angola",
    code: "AO",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+244",
  },
  {
    name: "Anguilla",
    code: "AI",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-264",
  },
  {
    name: "Antarctica",
    code: "AQ",
    timezone: "Pacific SA Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-268",
  },
  {
    name: "Argentina",
    code: "AR",
    timezone: "Argentina Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+54",
  },
  {
    name: "Armenia",
    code: "AM",
    timezone: "Caucasus Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+374",
  },
  {
    name: "Aruba",
    code: "AW",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+297",
  },
  {
    name: "Australia",
    code: "AU",
    timezone: "AUS Eastern Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+61",
  },
  {
    name: "Austria",
    code: "AT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+43",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    timezone: "Azerbaijan Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+994",
  },
  {
    name: "Bahamas, The",
    code: "BS",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-242",
  },
  {
    name: "Bahrain",
    code: "BH",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+973",
  },
  {
    name: "Bangladesh",
    code: "BD",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+880",
  },
  {
    name: "Barbados",
    code: "BB",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-246",
  },
  {
    name: "Belarus",
    code: "BY",
    timezone: "Belarus Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+375",
  },
  {
    name: "Belgium",
    code: "BE",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+32",
  },
  {
    name: "Belize",
    code: "BZ",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+501",
  },
  {
    name: "Benin",
    code: "BJ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+229",
  },
  {
    name: "Bermuda",
    code: "BM",
    timezone: "Atlantic Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-441",
  },
  {
    name: "Bhutan",
    code: "BT",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+975",
  },
  {
    name: "Bolivarian Republic of Venezuela",
    code: "VE",
    timezone: "Venezuela Standard Time",
    utc: "UTC-04:30",
    mobileCode: "+58",
  },
  {
    name: "Bolivia",
    code: "BO",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+591",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+387",
  },
  {
    name: "Botswana",
    code: "BW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+267",
  },
  {
    name: "Bouvet Island",
    code: "BV",
    timezone: "UTC",
    utc: "UTC",
    mobileCode: "+",
  },
  {
    name: "Brazil",
    code: "BR",
    timezone: "E. South America Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+55",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+246",
  },
  {
    name: "Brunei",
    code: "BN",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+673",
  },
  {
    name: "Bulgaria",
    code: "BG",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+359",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+226",
  },
  {
    name: "Burundi",
    code: "BI",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+257",
  },
  {
    name: "Cabo Verde",
    code: "CV",
    timezone: "Cape Verde Standard Time",
    utc: "UTC-01:00",
    mobileCode: "+238",
  },
  {
    name: "Cambodia",
    code: "KH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+855",
  },
  {
    name: "Cameroon",
    code: "CM",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+237",
  },
  {
    name: "Canada",
    code: "CA",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-345",
  },
  {
    name: "Central African Republic",
    code: "CF",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+236",
  },
  {
    name: "Chad",
    code: "TD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+235",
  },
  {
    name: "Chile",
    code: "CL",
    timezone: "Pacific SA Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+56",
  },
  {
    name: "China",
    code: "CN",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+86",
  },
  {
    name: "Christmas Island",
    code: "CX",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+61",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    timezone: "Myanmar Standard Time",
    utc: "UTC+06:30",
    mobileCode: "+61",
  },
  {
    name: "Colombia",
    code: "CO",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+57",
  },
  {
    name: "Comoros",
    code: "KM",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+269",
  },
  {
    name: "Congo",
    code: "CG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+242",
  },
  {
    name: "Congo (DRC)",
    code: "CD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+243",
  },
  {
    name: "Cook Islands",
    code: "CK",
    timezone: "Hawaiian Standard Time",
    utc: "UTC-10:00",
    mobileCode: "+682",
  },
  {
    name: "Costa Rica",
    code: "CR",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+506",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+225",
  },
  {
    name: "Croatia",
    code: "HR",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+385",
  },
  {
    name: "Cuba",
    code: "CU",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+53",
  },
  {
    name: "Curaçao",
    code: "CW",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
  },
  {
    name: "Cyprus",
    code: "CY",
    timezone: "E. Europe Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+357",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+420",
  },
  {
    name: "Democratic Republic of Timor-Leste",
    code: "TL",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+670",
  },
  {
    name: "Denmark",
    code: "DK",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+45",
  },
  {
    name: "Djibouti",
    code: "DJ",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+253",
  },
  {
    name: "Dominica",
    code: "DM",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-767",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-809 and 1-829",
  },
  {
    name: "Ecuador",
    code: "EC",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+593",
  },
  {
    name: "Egypt",
    code: "EG",
    timezone: "Egypt Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+20",
  },
  {
    name: "El Salvador",
    code: "SV",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+503",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+240",
  },
  {
    name: "Eritrea",
    code: "ER",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+291",
  },
  {
    name: "Estonia",
    code: "EE",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+372",
  },
  {
    name: "Ethiopia",
    code: "ET",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+251",
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    code: "FK",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+500",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+298",
  },
  {
    name: "Fiji Islands",
    code: "FJ",
    timezone: "Fiji Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+679",
  },
  {
    name: "Finland",
    code: "FI",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+358",
  },
  {
    name: "France",
    code: "FR",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+33",
  },
  {
    name: "French Guiana",
    code: "GF",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+594",
  },
  {
    name: "French Polynesia",
    code: "PF",
    timezone: "Hawaiian Standard Time",
    utc: "UTC-10:00",
    mobileCode: "+689",
  },
  {
    name: "French Southern and Antarctic Lands",
    code: "TF",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+",
  },
  {
    name: "Gabon",
    code: "GA",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+241",
  },
  {
    name: "Gambia, The",
    code: "GM",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+220",
  },
  {
    name: "Georgia",
    code: "GE",
    timezone: "Georgian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+995",
  },
  {
    name: "Germany",
    code: "DE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+49",
  },
  {
    name: "Ghana",
    code: "GH",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+233",
  },
  {
    name: "Gibraltar",
    code: "GI",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+350",
  },
  {
    name: "Greece",
    code: "GR",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+30",
  },
  {
    name: "Greenland",
    code: "GL",
    timezone: "Greenland Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+299",
  },
  {
    name: "Grenada",
    code: "GD",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-473",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
  },
  {
    name: "Guam",
    code: "GU",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+1-671",
  },
  {
    name: "Guatemala",
    code: "GT",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+502",
  },
  {
    name: "Guernsey",
    code: "GG",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1481",
  },
  {
    name: "Guinea",
    code: "GN",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+224",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+245",
  },
  {
    name: "Guyana",
    code: "GY",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+592",
  },
  {
    name: "Haiti",
    code: "HT",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+509",
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HM",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+ ",
  },
  {
    name: "Honduras",
    code: "HN",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+504",
  },
  {
    name: "Hong Kong SAR",
    code: "HK",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+852",
  },
  {
    name: "Hungary",
    code: "HU",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+36",
  },
  {
    name: "Iceland",
    code: "IS",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+354",
  },
  {
    name: "India",
    code: "IN",
    timezone: "India Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+91",
  },
  {
    name: "Indonesia",
    code: "ID",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+62",
  },
  {
    name: "Iran",
    code: "IR",
    timezone: "Iran Standard Time",
    utc: "UTC+03:30",
    mobileCode: "+98",
  },
  {
    name: "Iraq",
    code: "IQ",
    timezone: "Arabic Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+964",
  },
  {
    name: "Ireland",
    code: "IE",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+353",
  },
  {
    name: "Israel",
    code: "IL",
    timezone: "Israel Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+972",
  },
  {
    name: "Italy",
    code: "IT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+39",
  },
  {
    name: "Jamaica",
    code: "JM",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-876",
  },
  {
    name: "Jan Mayen",
    code: "SJ",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
  },
  {
    name: "Japan",
    code: "JP",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+81",
  },
  {
    name: "Jersey",
    code: "JE",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1534",
  },
  {
    name: "Jordan",
    code: "JO",
    timezone: "Jordan Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+962",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+7",
  },
  {
    name: "Kenya",
    code: "KE",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+254",
  },
  {
    name: "Kiribati",
    code: "KI",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+686",
  },
  {
    name: "Korea",
    code: "KR",
    timezone: "Korea Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+82",
  },
  {
    name: "Kosovo",
    code: "XK",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+",
  },
  {
    name: "Kuwait",
    code: "KW",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+965",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+996",
  },
  {
    name: "Laos",
    code: "LA",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+856",
  },
  {
    name: "Latvia",
    code: "LV",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+371",
  },
  {
    name: "Lebanon",
    code: "LB",
    timezone: "Middle East Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+961",
  },
  {
    name: "Lesotho",
    code: "LS",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+266",
  },
  {
    name: "Liberia",
    code: "LR",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+231",
  },
  {
    name: "Libya",
    code: "LY",
    timezone: "E. Europe Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+218",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+423",
  },
  {
    name: "Lithuania",
    code: "LT",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+370",
  },
  {
    name: "Luxembourg",
    code: "LU",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+352",
  },
  {
    name: "Macao SAR",
    code: "MO",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+853",
  },
  {
    name: "Macedonia, Former Yugoslav Republic of",
    code: "MK",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+389",
  },
  {
    name: "Madagascar",
    code: "MG",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+261",
  },
  {
    name: "Malawi",
    code: "MW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+265",
  },
  {
    name: "Malaysia",
    code: "MY",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+60",
  },
  {
    name: "Maldives",
    code: "MV",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+960",
  },
  {
    name: "Mali",
    code: "ML",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+223",
  },
  {
    name: "Malta",
    code: "MT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+356",
  },
  {
    name: "Man, Isle of",
    code: "IM",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1624",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+692",
  },
  {
    name: "Martinique",
    code: "MQ",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+596",
  },
  {
    name: "Mauritania",
    code: "MR",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+222",
  },
  {
    name: "Mauritius",
    code: "MU",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+230",
  },
  {
    name: "Mayotte",
    code: "YT",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+262",
  },
  {
    name: "Mexico",
    code: "MX",
    timezone: "Central Standard Time (Mexico)",
    utc: "UTC-06:00",
    mobileCode: "+52",
  },
  {
    name: "Micronesia",
    code: "FM",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+691",
  },
  {
    name: "Moldova",
    code: "MD",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+373",
  },
  {
    name: "Monaco",
    code: "MC",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+377",
  },
  {
    name: "Mongolia",
    code: "MN",
    timezone: "Ulaanbaatar Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+976",
  },
  {
    name: "Montenegro",
    code: "ME",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+382",
  },
  {
    name: "Montserrat",
    code: "MS",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-664",
  },
  {
    name: "Morocco",
    code: "MA",
    timezone: "Morocco Standard Time",
    utc: "UTC",
    mobileCode: "+212",
  },
  {
    name: "Mozambique",
    code: "MZ",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+258",
  },
  {
    name: "Myanmar",
    code: "MM",
    timezone: "Myanmar Standard Time",
    utc: "UTC+06:30",
    mobileCode: "+95",
  },
  {
    name: "Namibia",
    code: "NA",
    timezone: "Namibia Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+264",
  },
  {
    name: "Nauru",
    code: "NR",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+674",
  },
  {
    name: "Nepal",
    code: "NP",
    timezone: "Nepal Standard Time",
    utc: "UTC+05:45",
    mobileCode: "+977",
  },
  {
    name: "Netherlands",
    code: "NL",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+31",
  },
  {
    name: "New Caledonia",
    code: "NC",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+687",
  },
  {
    name: "New Zealand",
    code: "NZ",
    timezone: "New Zealand Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+64",
  },
  {
    name: "Nicaragua",
    code: "NI",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+505",
  },
  {
    name: "Niger",
    code: "NE",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+227",
  },
  {
    name: "Nigeria",
    code: "NG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+234",
  },
  {
    name: "Niue",
    code: "NU",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+683",
  },
  {
    name: "Norfolk Island",
    code: "NF",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+672",
  },
  {
    name: "North Korea",
    code: "KP",
    timezone: "Korea Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+850",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+1-670",
  },
  {
    name: "Norway",
    code: "NO",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
  },
  {
    name: "Oman",
    code: "OM",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+968",
  },
  {
    name: "Pakistan",
    code: "PK",
    timezone: "Pakistan Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+92",
  },
  {
    name: "Palau",
    code: "PW",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+680",
  },
  {
    name: "Palestinian Authority",
    code: "PS",
    timezone: "Egypt Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+970",
  },
  {
    name: "Panama",
    code: "PA",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+507",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+675",
  },
  {
    name: "Paraguay",
    code: "PY",
    timezone: "Paraguay Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+595",
  },
  {
    name: "Peru",
    code: "PE",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+51",
  },
  {
    name: "Philippines",
    code: "PH",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+63",
  },
  {
    name: "Pitcairn Islands",
    code: "PN",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+870",
  },
  {
    name: "Poland",
    code: "PL",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+48",
  },
  {
    name: "Portugal",
    code: "PT",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+351",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-787 and 1-939",
  },
  {
    name: "Qatar",
    code: "QA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+974",
  },
  {
    name: "Reunion",
    code: "RE",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+262",
  },
  {
    name: "Romania",
    code: "RO",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+40",
  },
  {
    name: "Russia",
    code: "RU",
    timezone: "Russian Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+7",
  },
  {
    name: "Rwanda",
    code: "RW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+250",
  },
  {
    name: "Saint Barthélemy",
    code: "BL",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+290",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-869",
  },
  {
    name: "Saint Lucia",
    code: "LC",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-758",
  },
  {
    name: "Saint Martin (French part)",
    code: "MF",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    timezone: "Greenland Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-784",
  },
  {
    name: "Samoa",
    code: "WS",
    timezone: "Samoa Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+685",
  },
  {
    name: "San Marino",
    code: "SM",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+378",
  },
  {
    name: "São Tomé and Príncipe",
    code: "ST",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+239",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+966",
  },
  {
    name: "Senegal",
    code: "SN",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+221",
  },
  {
    name: "Serbia",
    code: "RS",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+381",
  },
  {
    name: "Seychelles",
    code: "SC",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+248",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+232",
  },
  {
    name: "Singapore",
    code: "SG",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+65",
  },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SX",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
  },
  {
    name: "Slovakia",
    code: "SK",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+421",
  },
  {
    name: "Slovenia",
    code: "SI",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+386",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+677",
  },
  {
    name: "Somalia",
    code: "SO",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+252",
  },
  {
    name: "South Africa",
    code: "ZA",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+27",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    timezone: "UTC-02",
    utc: "UTC-02:00",
    mobileCode: "+",
  },
  {
    name: "South Sudan",
    code: "SS",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+211",
  },
  {
    name: "Spain",
    code: "ES",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+34",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    timezone: "Sri Lanka Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+94",
  },
  {
    name: "Sudan",
    code: "SD",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+249",
  },
  {
    name: "Suriname",
    code: "SR",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+597",
  },
  {
    name: "Svalbard",
    code: "SJ",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
  },
  {
    name: "Swaziland",
    code: "SZ",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+268",
  },
  {
    name: "Sweden",
    code: "SE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+46",
  },
  {
    name: "Switzerland",
    code: "CH",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+41",
  },
  {
    name: "Syria",
    code: "SY",
    timezone: "Syria Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+963",
  },
  {
    name: "Taiwan",
    code: "TW",
    timezone: "Taipei Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+886",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+992",
  },
  {
    name: "Tanzania",
    code: "TZ",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+255",
  },
  {
    name: "Thailand",
    code: "TH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+66",
  },
  {
    name: "Togo",
    code: "TG",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+228",
  },
  {
    name: "Tokelau",
    code: "TK",
    timezone: "Tonga Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+690",
  },
  {
    name: "Tonga",
    code: "TO",
    timezone: "Tonga Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+676",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-868",
  },
  {
    name: "Tunisia",
    code: "TN",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+216",
  },
  {
    name: "Turkey",
    code: "TR",
    timezone: "Turkey Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+90",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+993",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-649",
  },
  {
    name: "Tuvalu",
    code: "TV",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+688",
  },
  {
    name: "U.S. Minor Outlying Islands",
    code: "UM",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+1",
  },
  {
    name: "Uganda",
    code: "UG",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+256",
  },
  {
    name: "Ukraine",
    code: "UA",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+380",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+971",
  },
  {
    name: "United Kingdom",
    code: "GB",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44",
  },
  {
    name: "United States",
    code: "US",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+1",
  },
  {
    name: "Uruguay",
    code: "UY",
    timezone: "Montevideo Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+598",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+998",
  },
  {
    name: "Vanuatu",
    code: "VU",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+678",
  },
  {
    name: "Vatican City",
    code: "VA",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+379",
  },
  {
    name: "Vietnam",
    code: "VN",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+84",
  },
  {
    name: "Virgin Islands, U.S.",
    code: "VI",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-340",
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-284",
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+681",
  },
  {
    name: "Yemen",
    code: "YE",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+967",
  },
  {
    name: "Zambia",
    code: "ZM",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+260",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+263",
  },
];

export const COUNTRIES = [
  {
    name: "Afghanistan",
    code: "AF",
    timezone: "Afghanistan Standard Time",
    utc: "UTC+04:30",
    mobileCode: "+93",
    value: "Afghanistan",
  },
  {
    name: "Åland Islands",
    code: "AX",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+358-18",
    value: "Åland Islands",
  },
  {
    name: "Albania",
    code: "AL",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+355",
    value: "Albania",
  },
  {
    name: "Algeria",
    code: "DZ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+213",
    value: "Algeria",
  },
  {
    name: "American Samoa",
    code: "AS",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+1-684",
    value: "American Samoa",
  },
  {
    name: "Andorra",
    code: "AD",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+376",
    value: "Andorra",
  },
  {
    name: "Angola",
    code: "AO",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+244",
    value: "Angola",
  },
  {
    name: "Anguilla",
    code: "AI",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-264",
    value: "Anguilla",
  },
  {
    name: "Antarctica",
    code: "AQ",
    timezone: "Pacific SA Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+",
    value: "Antarctica",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-268",
    value: "Antigua and Barbuda",
  },
  {
    name: "Argentina",
    code: "AR",
    timezone: "Argentina Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+54",
    value: "Argentina",
  },
  {
    name: "Armenia",
    code: "AM",
    timezone: "Caucasus Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+374",
    value: "Armenia",
  },
  {
    name: "Aruba",
    code: "AW",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+297",
    value: "Aruba",
  },
  {
    name: "Australia",
    code: "AU",
    timezone: "AUS Eastern Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+61",
    value: "Australia",
  },
  {
    name: "Austria",
    code: "AT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+43",
    value: "Austria",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    timezone: "Azerbaijan Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+994",
    value: "Azerbaijan",
  },
  {
    name: "Bahamas, The",
    code: "BS",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-242",
    value: "Bahamas, The",
  },
  {
    name: "Bahrain",
    code: "BH",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+973",
    value: "Bahrain",
  },
  {
    name: "Bangladesh",
    code: "BD",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+880",
    value: "Bangladesh",
  },
  {
    name: "Barbados",
    code: "BB",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-246",
    value: "Barbados",
  },
  {
    name: "Belarus",
    code: "BY",
    timezone: "Belarus Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+375",
    value: "Belarus",
  },
  {
    name: "Belgium",
    code: "BE",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+32",
    value: "Belgium",
  },
  {
    name: "Belize",
    code: "BZ",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+501",
    value: "Belize",
  },
  {
    name: "Benin",
    code: "BJ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+229",
    value: "Benin",
  },
  {
    name: "Bermuda",
    code: "BM",
    timezone: "Atlantic Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-441",
    value: "Bermuda",
  },
  {
    name: "Bhutan",
    code: "BT",
    timezone: "Bangladesh Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+975",
    value: "Bhutan",
  },
  {
    name: "Bolivarian Republic of Venezuela",
    code: "VE",
    timezone: "Venezuela Standard Time",
    utc: "UTC-04:30",
    mobileCode: "+58",
    value: "Bolivarian Republic of Venezuela",
  },
  {
    name: "Bolivia",
    code: "BO",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+591",
    value: "Bolivia",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
    value: "Bonaire, Sint Eustatius and Saba",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+387",
    value: "Bosnia and Herzegovina",
  },
  {
    name: "Botswana",
    code: "BW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+267",
    value: "Botswana",
  },
  {
    name: "Bouvet Island",
    code: "BV",
    timezone: "UTC",
    utc: "UTC",
    mobileCode: "+",
    value: "Bouvet Island",
  },
  {
    name: "Brazil",
    code: "BR",
    timezone: "E. South America Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+55",
    value: "Brazil",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+246",
    value: "British Indian Ocean Territory",
  },
  {
    name: "Brunei",
    code: "BN",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+673",
    value: "Brunei",
  },
  {
    name: "Bulgaria",
    code: "BG",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+359",
    value: "Bulgaria",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+226",
    value: "Burkina Faso",
  },
  {
    name: "Burundi",
    code: "BI",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+257",
    value: "Burundi",
  },
  {
    name: "Cabo Verde",
    code: "CV",
    timezone: "Cape Verde Standard Time",
    utc: "UTC-01:00",
    mobileCode: "+238",
    value: "Cabo Verde",
  },
  {
    name: "Cambodia",
    code: "KH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+855",
    value: "Cambodia",
  },
  {
    name: "Cameroon",
    code: "CM",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+237",
    value: "Cameroon",
  },
  {
    name: "Canada",
    code: "CA",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1",
    value: "Canada",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-345",
    value: "Cayman Islands",
  },
  {
    name: "Central African Republic",
    code: "CF",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+236",
    value: "Central African Republic",
  },
  {
    name: "Chad",
    code: "TD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+235",
    value: "Chad",
  },
  {
    name: "Chile",
    code: "CL",
    timezone: "Pacific SA Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+56",
    value: "Chile",
  },
  {
    name: "China",
    code: "CN",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+86",
    value: "China",
  },
  {
    name: "Christmas Island",
    code: "CX",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+61",
    value: "Christmas Island",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    timezone: "Myanmar Standard Time",
    utc: "UTC+06:30",
    mobileCode: "+61",
    value: "Cocos (Keeling) Islands",
  },
  {
    name: "Colombia",
    code: "CO",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+57",
    value: "Colombia",
  },
  {
    name: "Comoros",
    code: "KM",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+269",
    value: "Comoros",
  },
  {
    name: "Congo",
    code: "CG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+242",
    value: "Congo",
  },
  {
    name: "Congo (DRC)",
    code: "CD",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+243",
    value: "Congo (DRC)",
  },
  {
    name: "Cook Islands",
    code: "CK",
    timezone: "Hawaiian Standard Time",
    utc: "UTC-10:00",
    mobileCode: "+682",
    value: "Cook Islands",
  },
  {
    name: "Costa Rica",
    code: "CR",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+506",
    value: "Costa Rica",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+225",
    value: "Côte d'Ivoire",
  },
  {
    name: "Croatia",
    code: "HR",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+385",
    value: "Croatia",
  },
  {
    name: "Cuba",
    code: "CU",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+53",
    value: "Cuba",
  },
  {
    name: "Curaçao",
    code: "CW",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
    value: "Curaçao",
  },
  {
    name: "Cyprus",
    code: "CY",
    timezone: "E. Europe Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+357",
    value: "Cyprus",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+420",
    value: "Czech Republic",
  },
  {
    name: "Democratic Republic of Timor-Leste",
    code: "TL",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+670",
    value: "Democratic Republic of Timor-Leste",
  },
  {
    name: "Denmark",
    code: "DK",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+45",
    value: "Denmark",
  },
  {
    name: "Djibouti",
    code: "DJ",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+253",
    value: "Djibouti",
  },
  {
    name: "Dominica",
    code: "DM",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-767",
    value: "Dominica",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-809 and 1-829",
    value: "Dominican Republic",
  },
  {
    name: "Ecuador",
    code: "EC",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+593",
    value: "Ecuador",
  },
  {
    name: "Egypt",
    code: "EG",
    timezone: "Egypt Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+20",
    value: "Egypt",
  },
  {
    name: "El Salvador",
    code: "SV",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+503",
    value: "El Salvador",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+240",
    value: "Equatorial Guinea",
  },
  {
    name: "Eritrea",
    code: "ER",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+291",
    value: "Eritrea",
  },
  {
    name: "Estonia",
    code: "EE",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+372",
    value: "Estonia",
  },
  {
    name: "Ethiopia",
    code: "ET",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+251",
    value: "Ethiopia",
  },
  {
    name: "Falkland Islands (Islas Malvinas)",
    code: "FK",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+500",
    value: "Falkland Islands (Islas Malvinas)",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+298",
    value: "Faroe Islands",
  },
  {
    name: "Fiji Islands",
    code: "FJ",
    timezone: "Fiji Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+679",
    value: "Fiji Islands",
  },
  {
    name: "Finland",
    code: "FI",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+358",
    value: "Finland",
  },
  {
    name: "France",
    code: "FR",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+33",
    value: "France",
  },
  {
    name: "French Guiana",
    code: "GF",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+594",
    value: "French Guiana",
  },
  {
    name: "French Polynesia",
    code: "PF",
    timezone: "Hawaiian Standard Time",
    utc: "UTC-10:00",
    mobileCode: "+689",
    value: "French Polynesia",
  },
  {
    name: "French Southern and Antarctic Lands",
    code: "TF",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+",
    value: "French Southern and Antarctic Lands",
  },
  {
    name: "Gabon",
    code: "GA",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+241",
    value: "Gabon",
  },
  {
    name: "Gambia, The",
    code: "GM",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+220",
    value: "Gambia, The",
  },
  {
    name: "Georgia",
    code: "GE",
    timezone: "Georgian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+995",
    value: "Georgia",
  },
  {
    name: "Germany",
    code: "DE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+49",
    value: "Germany",
  },
  {
    name: "Ghana",
    code: "GH",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+233",
    value: "Ghana",
  },
  {
    name: "Gibraltar",
    code: "GI",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+350",
    value: "Gibraltar",
  },
  {
    name: "Greece",
    code: "GR",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+30",
    value: "Greece",
  },
  {
    name: "Greenland",
    code: "GL",
    timezone: "Greenland Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+299",
    value: "Greenland",
  },
  {
    name: "Grenada",
    code: "GD",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-473",
    value: "Grenada",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
    value: "Guadeloupe",
  },
  {
    name: "Guam",
    code: "GU",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+1-671",
    value: "Guam",
  },
  {
    name: "Guatemala",
    code: "GT",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+502",
    value: "Guatemala",
  },
  {
    name: "Guernsey",
    code: "GG",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1481",
    value: "Guernsey",
  },
  {
    name: "Guinea",
    code: "GN",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+224",
    value: "Guinea",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+245",
    value: "Guinea-Bissau",
  },
  {
    name: "Guyana",
    code: "GY",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+592",
    value: "Guyana",
  },
  {
    name: "Haiti",
    code: "HT",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+509",
    value: "Haiti",
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HM",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+ ",
    value: "Heard Island and McDonald Islands",
  },
  {
    name: "Honduras",
    code: "HN",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+504",
    value: "Honduras",
  },
  {
    name: "Hong Kong SAR",
    code: "HK",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+852",
    value: "Hong Kong SAR",
  },
  {
    name: "Hungary",
    code: "HU",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+36",
    value: "Hungary",
  },
  {
    name: "Iceland",
    code: "IS",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+354",
    value: "Iceland",
  },
  {
    name: "India",
    code: "IN",
    timezone: "India Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+91",
    value: "India",
  },
  {
    name: "Indonesia",
    code: "ID",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+62",
    value: "Indonesia",
  },
  {
    name: "Iran",
    code: "IR",
    timezone: "Iran Standard Time",
    utc: "UTC+03:30",
    mobileCode: "+98",
    value: "Iran",
  },
  {
    name: "Iraq",
    code: "IQ",
    timezone: "Arabic Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+964",
    value: "Iraq",
  },
  {
    name: "Ireland",
    code: "IE",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+353",
    value: "Ireland",
  },
  {
    name: "Israel",
    code: "IL",
    timezone: "Israel Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+972",
    value: "Israel",
  },
  {
    name: "Italy",
    code: "IT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+39",
    value: "Italy",
  },
  {
    name: "Jamaica",
    code: "JM",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-876",
    value: "Jamaica",
  },
  {
    name: "Jan Mayen",
    code: "SJ",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
    value: "Jan Mayen",
  },
  {
    name: "Japan",
    code: "JP",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+81",
    value: "Japan",
  },
  {
    name: "Jersey",
    code: "JE",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1534",
    value: "Jersey",
  },
  {
    name: "Jordan",
    code: "JO",
    timezone: "Jordan Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+962",
    value: "Jordan",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+7",
    value: "Kazakhstan",
  },
  {
    name: "Kenya",
    code: "KE",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+254",
    value: "Kenya",
  },
  {
    name: "Kiribati",
    code: "KI",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+686",
    value: "Kiribati",
  },
  {
    name: "Korea",
    code: "KR",
    timezone: "Korea Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+82",
    value: "Korea",
  },
  {
    name: "Kosovo",
    code: "XK",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+",
    value: "Kosovo",
  },
  {
    name: "Kuwait",
    code: "KW",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+965",
    value: "Kuwait",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    timezone: "Central Asia Standard Time",
    utc: "UTC+06:00",
    mobileCode: "+996",
    value: "Kyrgyzstan",
  },
  {
    name: "Laos",
    code: "LA",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+856",
    value: "Laos",
  },
  {
    name: "Latvia",
    code: "LV",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+371",
    value: "Latvia",
  },
  {
    name: "Lebanon",
    code: "LB",
    timezone: "Middle East Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+961",
    value: "Lebanon",
  },
  {
    name: "Lesotho",
    code: "LS",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+266",
    value: "Lesotho",
  },
  {
    name: "Liberia",
    code: "LR",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+231",
    value: "Liberia",
  },
  {
    name: "Libya",
    code: "LY",
    timezone: "E. Europe Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+218",
    value: "Libya",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+423",
    value: "Liechtenstein",
  },
  {
    name: "Lithuania",
    code: "LT",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+370",
    value: "Lithuania",
  },
  {
    name: "Luxembourg",
    code: "LU",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+352",
    value: "Luxembourg",
  },
  {
    name: "Macao SAR",
    code: "MO",
    timezone: "China Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+853",
    value: "Macao SAR",
  },
  {
    name: "Macedonia, Former Yugoslav Republic of",
    code: "MK",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+389",
    value: "Macedonia, Former Yugoslav Republic of",
  },
  {
    name: "Madagascar",
    code: "MG",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+261",
    value: "Madagascar",
  },
  {
    name: "Malawi",
    code: "MW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+265",
    value: "Malawi",
  },
  {
    name: "Malaysia",
    code: "MY",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+60",
    value: "Malaysia",
  },
  {
    name: "Maldives",
    code: "MV",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+960",
    value: "Maldives",
  },
  {
    name: "Mali",
    code: "ML",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+223",
    value: "Mali",
  },
  {
    name: "Malta",
    code: "MT",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+356",
    value: "Malta",
  },
  {
    name: "Man, Isle of",
    code: "IM",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44-1624",
    value: "Man, Isle of",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+692",
    value: "Marshall Islands",
  },
  {
    name: "Martinique",
    code: "MQ",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+596",
    value: "Martinique",
  },
  {
    name: "Mauritania",
    code: "MR",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+222",
    value: "Mauritania",
  },
  {
    name: "Mauritius",
    code: "MU",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+230",
    value: "Mauritius",
  },
  {
    name: "Mayotte",
    code: "YT",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+262",
    value: "Mayotte",
  },
  {
    name: "Mexico",
    code: "MX",
    timezone: "Central Standard Time (Mexico)",
    utc: "UTC-06:00",
    mobileCode: "+52",
    value: "Mexico",
  },
  {
    name: "Micronesia",
    code: "FM",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+691",
    value: "Micronesia",
  },
  {
    name: "Moldova",
    code: "MD",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+373",
    value: "Moldova",
  },
  {
    name: "Monaco",
    code: "MC",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+377",
    value: "Monaco",
  },
  {
    name: "Mongolia",
    code: "MN",
    timezone: "Ulaanbaatar Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+976",
    value: "Mongolia",
  },
  {
    name: "Montenegro",
    code: "ME",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+382",
    value: "Montenegro",
  },
  {
    name: "Montserrat",
    code: "MS",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-664",
    value: "Montserrat",
  },
  {
    name: "Morocco",
    code: "MA",
    timezone: "Morocco Standard Time",
    utc: "UTC",
    mobileCode: "+212",
    value: "Morocco",
  },
  {
    name: "Mozambique",
    code: "MZ",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+258",
    value: "Mozambique",
  },
  {
    name: "Myanmar",
    code: "MM",
    timezone: "Myanmar Standard Time",
    utc: "UTC+06:30",
    mobileCode: "+95",
    value: "Myanmar",
  },
  {
    name: "Namibia",
    code: "NA",
    timezone: "Namibia Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+264",
    value: "Namibia",
  },
  {
    name: "Nauru",
    code: "NR",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+674",
    value: "Nauru",
  },
  {
    name: "Nepal",
    code: "NP",
    timezone: "Nepal Standard Time",
    utc: "UTC+05:45",
    mobileCode: "+977",
    value: "Nepal",
  },
  {
    name: "Netherlands",
    code: "NL",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+31",
    value: "Netherlands",
  },
  {
    name: "New Caledonia",
    code: "NC",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+687",
    value: "New Caledonia",
  },
  {
    name: "New Zealand",
    code: "NZ",
    timezone: "New Zealand Standard Time",
    utc: "UTC+12:00",
    mobileCode: "+64",
    value: "New Zealand",
  },
  {
    name: "Nicaragua",
    code: "NI",
    timezone: "Central America Standard Time",
    utc: "UTC-06:00",
    mobileCode: "+505",
    value: "Nicaragua",
  },
  {
    name: "Niger",
    code: "NE",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+227",
    value: "Niger",
  },
  {
    name: "Nigeria",
    code: "NG",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+234",
    value: "Nigeria",
  },
  {
    name: "Niue",
    code: "NU",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+683",
    value: "Niue",
  },
  {
    name: "Norfolk Island",
    code: "NF",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+672",
    value: "Norfolk Island",
  },
  {
    name: "North Korea",
    code: "KP",
    timezone: "Korea Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+850",
    value: "North Korea",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+1-670",
    value: "Northern Mariana Islands",
  },
  {
    name: "Norway",
    code: "NO",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
    value: "Norway",
  },
  {
    name: "Oman",
    code: "OM",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+968",
    value: "Oman",
  },
  {
    name: "Pakistan",
    code: "PK",
    timezone: "Pakistan Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+92",
    value: "Pakistan",
  },
  {
    name: "Palau",
    code: "PW",
    timezone: "Tokyo Standard Time",
    utc: "UTC+09:00",
    mobileCode: "+680",
    value: "Palau",
  },
  {
    name: "Palestinian Authority",
    code: "PS",
    timezone: "Egypt Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+970",
    value: "Palestinian Authority",
  },
  {
    name: "Panama",
    code: "PA",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+507",
    value: "Panama",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    timezone: "West Pacific Standard Time",
    utc: "UTC+10:00",
    mobileCode: "+675",
    value: "Papua New Guinea",
  },
  {
    name: "Paraguay",
    code: "PY",
    timezone: "Paraguay Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+595",
    value: "Paraguay",
  },
  {
    name: "Peru",
    code: "PE",
    timezone: "SA Pacific Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+51",
    value: "Peru",
  },
  {
    name: "Philippines",
    code: "PH",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+63",
    value: "Philippines",
  },
  {
    name: "Pitcairn Islands",
    code: "PN",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+870",
    value: "Pitcairn Islands",
  },
  {
    name: "Poland",
    code: "PL",
    timezone: "Central European Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+48",
    value: "Poland",
  },
  {
    name: "Portugal",
    code: "PT",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+351",
    value: "Portugal",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-787 and 1-939",
    value: "Puerto Rico",
  },
  {
    name: "Qatar",
    code: "QA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+974",
    value: "Qatar",
  },
  {
    name: "Reunion",
    code: "RE",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+262",
    value: "Reunion",
  },
  {
    name: "Romania",
    code: "RO",
    timezone: "GTB Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+40",
    value: "Romania",
  },
  {
    name: "Russia",
    code: "RU",
    timezone: "Russian Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+7",
    value: "Russia",
  },
  {
    name: "Rwanda",
    code: "RW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+250",
    value: "Rwanda",
  },
  {
    name: "Saint Barthélemy",
    code: "BL",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
    value: "Saint Barthélemy",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+290",
    value: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-869",
    value: "Saint Kitts and Nevis",
  },
  {
    name: "Saint Lucia",
    code: "LC",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-758",
    value: "Saint Lucia",
  },
  {
    name: "Saint Martin (French part)",
    code: "MF",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+590",
    value: "Saint Martin (French part)",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    timezone: "Greenland Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+508",
    value: "Saint Pierre and Miquelon",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-784",
    value: "Saint Vincent and the Grenadines",
  },
  {
    name: "Samoa",
    code: "WS",
    timezone: "Samoa Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+685",
    value: "Samoa",
  },
  {
    name: "San Marino",
    code: "SM",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+378",
    value: "San Marino",
  },
  {
    name: "São Tomé and Príncipe",
    code: "ST",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+239",
    value: "São Tomé and Príncipe",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+966",
    value: "Saudi Arabia",
  },
  {
    name: "Senegal",
    code: "SN",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+221",
    value: "Senegal",
  },
  {
    name: "Serbia",
    code: "RS",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+381",
    value: "Serbia",
  },
  {
    name: "Seychelles",
    code: "SC",
    timezone: "Mauritius Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+248",
    value: "Seychelles",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+232",
    value: "Sierra Leone",
  },
  {
    name: "Singapore",
    code: "SG",
    timezone: "Singapore Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+65",
    value: "Singapore",
  },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SX",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+599",
    value: "Sint Maarten (Dutch part)",
  },
  {
    name: "Slovakia",
    code: "SK",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+421",
    value: "Slovakia",
  },
  {
    name: "Slovenia",
    code: "SI",
    timezone: "Central Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+386",
    value: "Slovenia",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+677",
    value: "Solomon Islands",
  },
  {
    name: "Somalia",
    code: "SO",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+252",
    value: "Somalia",
  },
  {
    name: "South Africa",
    code: "ZA",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+27",
    value: "South Africa",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    timezone: "UTC-02",
    utc: "UTC-02:00",
    mobileCode: "+",
    value: "South Georgia and the South Sandwich Islands",
  },
  {
    name: "South Sudan",
    code: "SS",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+211",
    value: "South Sudan",
  },
  {
    name: "Spain",
    code: "ES",
    timezone: "Romance Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+34",
    value: "Spain",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    timezone: "Sri Lanka Standard Time",
    utc: "UTC+05:30",
    mobileCode: "+94",
    value: "Sri Lanka",
  },
  {
    name: "Sudan",
    code: "SD",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+249",
    value: "Sudan",
  },
  {
    name: "Suriname",
    code: "SR",
    timezone: "SA Eastern Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+597",
    value: "Suriname",
  },
  {
    name: "Svalbard",
    code: "SJ",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+47",
    value: "Svalbard",
  },
  {
    name: "Swaziland",
    code: "SZ",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+268",
    value: "Swaziland",
  },
  {
    name: "Sweden",
    code: "SE",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+46",
    value: "Sweden",
  },
  {
    name: "Switzerland",
    code: "CH",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+41",
    value: "Switzerland",
  },
  {
    name: "Syria",
    code: "SY",
    timezone: "Syria Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+963",
    value: "Syria",
  },
  {
    name: "Taiwan",
    code: "TW",
    timezone: "Taipei Standard Time",
    utc: "UTC+08:00",
    mobileCode: "+886",
    value: "Taiwan",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+992",
    value: "Tajikistan",
  },
  {
    name: "Tanzania",
    code: "TZ",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+255",
    value: "Tanzania",
  },
  {
    name: "Thailand",
    code: "TH",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+66",
    value: "Thailand",
  },
  {
    name: "Togo",
    code: "TG",
    timezone: "Greenwich Standard Time",
    utc: "UTC",
    mobileCode: "+228",
    value: "Togo",
  },
  {
    name: "Tokelau",
    code: "TK",
    timezone: "Tonga Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+690",
    value: "Tokelau",
  },
  {
    name: "Tonga",
    code: "TO",
    timezone: "Tonga Standard Time",
    utc: "UTC+13:00",
    mobileCode: "+676",
    value: "Tonga",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-868",
    value: "Trinidad and Tobago",
  },
  {
    name: "Tunisia",
    code: "TN",
    timezone: "W. Central Africa Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+216",
    value: "Tunisia",
  },
  {
    name: "Turkey",
    code: "TR",
    timezone: "Turkey Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+90",
    value: "Turkey",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+993",
    value: "Turkmenistan",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    timezone: "Eastern Standard Time",
    utc: "UTC-05:00",
    mobileCode: "+1-649",
    value: "Turks and Caicos Islands",
  },
  {
    name: "Tuvalu",
    code: "TV",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+688",
    value: "Tuvalu",
  },
  {
    name: "U.S. Minor Outlying Islands",
    code: "UM",
    timezone: "UTC-11",
    utc: "UTC-11:00",
    mobileCode: "+1",
    value: "U.S. Minor Outlying Islands",
  },
  {
    name: "Uganda",
    code: "UG",
    timezone: "E. Africa Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+256",
    value: "Uganda",
  },
  {
    name: "Ukraine",
    code: "UA",
    timezone: "FLE Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+380",
    value: "Ukraine",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    timezone: "Arabian Standard Time",
    utc: "UTC+04:00",
    mobileCode: "+971",
    value: "United Arab Emirates",
  },
  {
    name: "United Kingdom",
    code: "GB",
    timezone: "GMT Standard Time",
    utc: "UTC",
    mobileCode: "+44",
    value: "United Kingdom",
  },
  {
    name: "United States",
    code: "US",
    timezone: "Pacific Standard Time",
    utc: "UTC-08:00",
    mobileCode: "+1",
    value: "United States",
  },
  {
    name: "Uruguay",
    code: "UY",
    timezone: "Montevideo Standard Time",
    utc: "UTC-03:00",
    mobileCode: "+598",
    value: "Uruguay",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    timezone: "West Asia Standard Time",
    utc: "UTC+05:00",
    mobileCode: "+998",
    value: "Uzbekistan",
  },
  {
    name: "Vanuatu",
    code: "VU",
    timezone: "Central Pacific Standard Time",
    utc: "UTC+11:00",
    mobileCode: "+678",
    value: "Vanuatu",
  },
  {
    name: "Vatican City",
    code: "VA",
    timezone: "W. Europe Standard Time",
    utc: "UTC+01:00",
    mobileCode: "+379",
    value: "Vatican City",
  },
  {
    name: "Vietnam",
    code: "VN",
    timezone: "SE Asia Standard Time",
    utc: "UTC+07:00",
    mobileCode: "+84",
    value: "Vietnam",
  },
  {
    name: "Virgin Islands, U.S.",
    code: "VI",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-340",
    value: "Virgin Islands, U.S.",
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
    timezone: "SA Western Standard Time",
    utc: "UTC-04:00",
    mobileCode: "+1-284",
    value: "Virgin Islands, British",
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    timezone: "UTC+12",
    utc: "UTC+12:00",
    mobileCode: "+681",
    value: "Wallis and Futuna",
  },
  {
    name: "Yemen",
    code: "YE",
    timezone: "Arab Standard Time",
    utc: "UTC+03:00",
    mobileCode: "+967",
    value: "Yemen",
  },
  {
    name: "Zambia",
    code: "ZM",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+260",
    value: "Zambia",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    timezone: "South Africa Standard Time",
    utc: "UTC+02:00",
    mobileCode: "+263",
    value: "Zimbabwe",
  },
];

export const PHONE_CODES: any = {
  Afghanistan: "+93",
  "Åland Islands": "+358-18",
  Albania: "+355",
  Algeria: "+213",
  "American Samoa": "+1-684",
  Andorra: "+376",
  Angola: "+244",
  Anguilla: "+1-264",
  Antarctica: "+",
  "Antigua and Barbuda": "+1-268",
  Argentina: "+54",
  Armenia: "+374",
  Aruba: "+297",
  Australia: "+61",
  Austria: "+43",
  Azerbaijan: "+994",
  "Bahamas, The": "+1-242",
  Bahrain: "+973",
  Bangladesh: "+880",
  Barbados: "+1-246",
  Belarus: "+375",
  Belgium: "+32",
  Belize: "+501",
  Benin: "+229",
  Bermuda: "+1-441",
  Bhutan: "+975",
  "Bolivarian Republic of Venezuela": "+58",
  Bolivia: "+591",
  "Bonaire, Sint Eustatius and Saba": "+599",
  "Bosnia and Herzegovina": "+387",
  Botswana: "+267",
  "Bouvet Island": "+",
  Brazil: "+55",
  "British Indian Ocean Territory": "+246",
  Brunei: "+673",
  Bulgaria: "+359",
  "Burkina Faso": "+226",
  Burundi: "+257",
  "Cabo Verde": "+238",
  Cambodia: "+855",
  Cameroon: "+237",
  Canada: "+1",
  "Cayman Islands": "+1-345",
  "Central African Republic": "+236",
  Chad: "+235",
  Chile: "+56",
  China: "+86",
  "Christmas Island": "+61",
  "Cocos (Keeling) Islands": "+61",
  Colombia: "+57",
  Comoros: "+269",
  Congo: "+242",
  "Congo (DRC)": "+243",
  "Cook Islands": "+682",
  "Costa Rica": "+506",
  "Côte d'Ivoire": "+225",
  Croatia: "+385",
  Cuba: "+53",
  Curaçao: "+599",
  Cyprus: "+357",
  "Czech Republic": "+420",
  "Democratic Republic of Timor-Leste": "+670",
  Denmark: "+45",
  Djibouti: "+253",
  Dominica: "+1-767",
  "Dominican Republic": "+1-809 and 1-829",
  Ecuador: "+593",
  Egypt: "+20",
  "El Salvador": "+503",
  "Equatorial Guinea": "+240",
  Eritrea: "+291",
  Estonia: "+372",
  Ethiopia: "+251",
  "Falkland Islands (Islas Malvinas)": "+500",
  "Faroe Islands": "+298",
  "Fiji Islands": "+679",
  Finland: "+358",
  France: "+33",
  "French Guiana": "+594",
  "French Polynesia": "+689",
  "French Southern and Antarctic Lands": "+",
  Gabon: "+241",
  "Gambia, The": "+220",
  Georgia: "+995",
  Germany: "+49",
  Ghana: "+233",
  Gibraltar: "+350",
  Greece: "+30",
  Greenland: "+299",
  Grenada: "+1-473",
  Guadeloupe: "+590",
  Guam: "+1-671",
  Guatemala: "+502",
  Guernsey: "+44-1481",
  Guinea: "+224",
  "Guinea-Bissau": "+245",
  Guyana: "+592",
  Haiti: "+509",
  "Heard Island and McDonald Islands": "+ ",
  Honduras: "+504",
  "Hong Kong SAR": "+852",
  Hungary: "+36",
  Iceland: "+354",
  India: "+91",
  Indonesia: "+62",
  Iran: "+98",
  Iraq: "+964",
  Ireland: "+353",
  Israel: "+972",
  Italy: "+39",
  Jamaica: "+1-876",
  "Jan Mayen": "+47",
  Japan: "+81",
  Jersey: "+44-1534",
  Jordan: "+962",
  Kazakhstan: "+7",
  Kenya: "+254",
  Kiribati: "+686",
  Korea: "+82",
  Kosovo: "+",
  Kuwait: "+965",
  Kyrgyzstan: "+996",
  Laos: "+856",
  Latvia: "+371",
  Lebanon: "+961",
  Lesotho: "+266",
  Liberia: "+231",
  Libya: "+218",
  Liechtenstein: "+423",
  Lithuania: "+370",
  Luxembourg: "+352",
  "Macao SAR": "+853",
  "Macedonia, Former Yugoslav Republic of": "+389",
  Madagascar: "+261",
  Malawi: "+265",
  Malaysia: "+60",
  Maldives: "+960",
  Mali: "+223",
  Malta: "+356",
  "Man, Isle of": "+44-1624",
  "Marshall Islands": "+692",
  Martinique: "+596",
  Mauritania: "+222",
  Mauritius: "+230",
  Mayotte: "+262",
  Mexico: "+52",
  Micronesia: "+691",
  Moldova: "+373",
  Monaco: "+377",
  Mongolia: "+976",
  Montenegro: "+382",
  Montserrat: "+1-664",
  Morocco: "+212",
  Mozambique: "+258",
  Myanmar: "+95",
  Namibia: "+264",
  Nauru: "+674",
  Nepal: "+977",
  Netherlands: "+31",
  "New Caledonia": "+687",
  "New Zealand": "+64",
  Nicaragua: "+505",
  Niger: "+227",
  Nigeria: "+234",
  Niue: "+683",
  "Norfolk Island": "+672",
  "North Korea": "+850",
  "Northern Mariana Islands": "+1-670",
  Norway: "+47",
  Oman: "+968",
  Pakistan: "+92",
  Palau: "+680",
  "Palestinian Authority": "+970",
  Panama: "+507",
  "Papua New Guinea": "+675",
  Paraguay: "+595",
  Peru: "+51",
  Philippines: "+63",
  "Pitcairn Islands": "+870",
  Poland: "+48",
  Portugal: "+351",
  "Puerto Rico": "+1-787 and 1-939",
  Qatar: "+974",
  Reunion: "+262",
  Romania: "+40",
  Russia: "+7",
  Rwanda: "+250",
  "Saint Barthélemy": "+590",
  "Saint Helena, Ascension and Tristan da Cunha": "+290",
  "Saint Kitts and Nevis": "+1-869",
  "Saint Lucia": "+1-758",
  "Saint Martin (French part)": "+590",
  "Saint Pierre and Miquelon": "+508",
  "Saint Vincent and the Grenadines": "+1-784",
  Samoa: "+685",
  "San Marino": "+378",
  "São Tomé and Príncipe": "+239",
  "Saudi Arabia": "+966",
  Senegal: "+221",
  Serbia: "+381",
  Seychelles: "+248",
  "Sierra Leone": "+232",
  Singapore: "+65",
  "Sint Maarten (Dutch part)": "+599",
  Slovakia: "+421",
  Slovenia: "+386",
  "Solomon Islands": "+677",
  Somalia: "+252",
  "South Africa": "+27",
  "South Georgia and the South Sandwich Islands": "+",
  "South Sudan": "+211",
  Spain: "+34",
  "Sri Lanka": "+94",
  Sudan: "+249",
  Suriname: "+597",
  Svalbard: "+47",
  Swaziland: "+268",
  Sweden: "+46",
  Switzerland: "+41",
  Syria: "+963",
  Taiwan: "+886",
  Tajikistan: "+992",
  Tanzania: "+255",
  Thailand: "+66",
  Togo: "+228",
  Tokelau: "+690",
  Tonga: "+676",
  "Trinidad and Tobago": "+1-868",
  Tunisia: "+216",
  Turkey: "+90",
  Turkmenistan: "+993",
  "Turks and Caicos Islands": "+1-649",
  Tuvalu: "+688",
  "U.S. Minor Outlying Islands": "+1",
  Uganda: "+256",
  Ukraine: "+380",
  "United Arab Emirates": "+971",
  "United Kingdom": "+44",
  "United States": "+1",
  Uruguay: "+598",
  Uzbekistan: "+998",
  Vanuatu: "+678",
  "Vatican City": "+379",
  Vietnam: "+84",
  "Virgin Islands, U.S.": "+1-340",
  "Virgin Islands, British": "+1-284",
  "Wallis and Futuna": "+681",
  Yemen: "+967",
  Zambia: "+260",
  Zimbabwe: "+263",
};
