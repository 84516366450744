import styled from "styled-components";

export const AppContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  font-family: Public Sans;
  background: #fbfbfb;
  padding: 40px 24px;
  font-weight: normal;
`;
