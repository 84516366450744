import React, { FC, useEffect, useState, useContext } from "react";
import { OrderContext } from "../OrderModal/OrderModal";
import { useMediaQuery } from "react-responsive";
import { Tooltip } from "antd";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import Buttons from "../Buttons/Buttons";
import placeholder from "./Images/placeholder.jpeg";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
  CheckContainer,
  CheckWrapper,
  Contents,
  CTAWrapper,
  ImageWrapper,
  PopConfirm,
  PopConfirmWrapper,
  RingSizeWrapper,
  Selector,
  SelectorDescriptor,
  SelectorImgContainer,
  SelectorWrapper,
  Stage1Wrapper,
  Subject,
  TermsText,
  TItemWrapper,
  TOCContainer,
  TOCWrapper,
  ToolTipSpan,
} from "./Stage1.style";

interface Stage1Props {
  dealMembers: any[];
  onCancel: () => void;
}

const RingSizeToolTip =
  "You can proceed with your order if you do not find your ring size, or if you are unsure of your ring size. We have many options available for you.";

const Stage1: FC<Stage1Props> = ({ dealMembers, onCancel }) => {
  const OContext = useContext(OrderContext);
  const [dealIdSelected, setDealIdSelected] = useState<any>();
  const [dealObj, setDealObj] = useState<any>();
  const [termsAgreed, setTermsAgreed] = useState(false);

  const isMobile = useMediaQuery({
    query: "(min-width: 375px)",
  });

  const { details = {}, setDetails } = OContext!;

  useEffect(() => {
    if (dealIdSelected) {
      const obj = dealMembers.find((deal) => deal.title === dealIdSelected);

      setDealObj(obj);
    }
  }, [dealIdSelected, dealMembers]);

  const availableDealIds = dealMembers.map((deal, i) => {
    return {
      key: `${deal.dealId}-${i}`,
      value: deal.title,
      label: deal.title,
    };
  });

  const ringSizes = [
    {
      value: 5,
      label: 5,
    },
    {
      value: 5.5,
      label: 5.5,
    },
    {
      value: 6,
      label: 6,
    },
    {
      value: 6.5,
      label: 6.5,
    },
    {
      value: 7,
      label: 7,
    },
    {
      value: 7.5,
      label: 7.5,
    },
    {
      value: 8,
      label: 8,
    },
    {
      value: 8.5,
      label: 8.5,
    },
    {
      value: 9,
      label: 9,
    },
    {
      value: 9.5,
      label: 9.5,
    },
    {
      value: 10,
      label: 10,
    },
    {
      value: 10.5,
      label: 10.5,
    },
    {
      value: 11,
      label: 11,
    },
    {
      value: 12,
      label: 12,
    },
  ];

  const handleChange = (value: string) => {
    setDealIdSelected(value);
  };

  const handleNext = () => {
    const { dealId, itemId, onlineStoreUrl, title, price } = dealObj;

    setDetails({
      ...details,
      dealId,
      itemId,
      onlineStoreUrl,
      title,
      image: dealObj?.featuredImageUrl,
      itemPrice: price,
    });
    OContext?.setStage(1);
  };

  const confirm = () => {
    setTermsAgreed(true);
  };
  const cancelAgreement = () => {
    setTermsAgreed(false);
  };
  return (
    <Stage1Wrapper>
      <SelectorImgContainer>
        <SelectorWrapper>
          <SelectorDescriptor> Select Item </SelectorDescriptor>
          <Selector
            defaultValue={dealIdSelected}
            style={{ width: isMobile ? 300 : 350 }}
            onChange={(e) => handleChange(e as string)}
            options={availableDealIds}
          />
          <SelectorDescriptor>
            Ring Size
            <Tooltip
              title={RingSizeToolTip}
              overlayInnerStyle={{ padding: "10px" }}
              overlayStyle={{ padding: "10px" }}
            >
              <ToolTipSpan>
                <QuestionCircleOutlined />
              </ToolTipSpan>
            </Tooltip>
          </SelectorDescriptor>
          <RingSizeWrapper>
            <Selector
              defaultValue={details?.ringSize}
              style={{ width: 100 }}
              onChange={(e) => setDetails({ ...details, ringSize: e })}
              options={ringSizes}
            />
          </RingSizeWrapper>
          <TOCWrapper>
            <PopConfirmWrapper>
              <PopConfirm
                title="Terms and Conditions"
                description={<TOC />}
                onConfirm={confirm}
                onCancel={cancelAgreement}
                okText="I Agree"
              >
                <CheckContainer>
                  <CheckWrapper>
                    {termsAgreed ? (
                      <ImCheckboxChecked />
                    ) : (
                      <ImCheckboxUnchecked />
                    )}
                  </CheckWrapper>
                  <TermsText>
                    I agree with the Terms and Conditions of this deal
                  </TermsText>
                </CheckContainer>
              </PopConfirm>
            </PopConfirmWrapper>
          </TOCWrapper>
        </SelectorWrapper>

        <ImageWrapper>
          {dealObj ? (
            <img
              src={dealObj?.featuredImageUrl}
              alt={dealObj?.title}
              width={150}
            />
          ) : (
            <img src={placeholder} alt={dealObj?.title} width={150} />
          )}
        </ImageWrapper>
      </SelectorImgContainer>
      <CTAWrapper>
        <Buttons text="Close" type="default" onClick={onCancel} />
        <Buttons
          text="Next"
          type="primary"
          onClick={handleNext}
          disabled={!dealObj?.title || termsAgreed === false}
        />
      </CTAWrapper>
    </Stage1Wrapper>
  );
};

const TOC = () => {
  const terms = [
    {
      subject: "Items Are Subject to availability",
      contents:
        "We may have availability at the time of your purchase, but we are not obliged to hold the item for you in your cart and the same item is available to other users for the same time. If we confirm a payment for an order from a customer before you’re able to make payment, your order will unfortunately no longer be valid. All items are subject to availability.",
    },
    {
      subject: "Final Sales Policy",
      contents:
        "Once you make a purchase for an item on sale, you will not be able to return that item for a replacement or refund. All sales final policies are typically applied to clearance sale items.",
    },
    {
      subject: "No cash refund policy",
      contents: "No monetary refunds will be given when an item is purchased.",
    },
    {
      subject: "No refund, exchange only policy",
      contents:
        "You can only exchange items where a you wish to return and item will only be acceptable in good condition.",
    },
  ];

  return (
    <TOCContainer>
      {terms.map((term, i) => {
        return (
          <TItemWrapper key={`TOCitem-${i}`}>
            <Subject>{`${term.subject} - `} </Subject>
            <Contents>{term.contents} </Contents>
          </TItemWrapper>
        );
      })}
    </TOCContainer>
  );
};

export default Stage1;
