import React from "react";
import Footer from "../../atoms/Footer";
import DealsRenderI from "../../organisms/DealsRender/DealsRenderI";
import DealsSelectorI from "../../organisms/DealsSelector/DealsSelectorI";
import InstagramProvider from "./context";
import { MainPageWrapperI } from "./Instagram.style";

const Instagram = () => {
  return (
    <InstagramProvider>
      <MainPageWrapperI>
        <DealsSelectorI />
        <DealsRenderI />
        <Footer />
      </MainPageWrapperI>
    </InstagramProvider>
  );
};

export default Instagram;
