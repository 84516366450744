import styled from "styled-components";
import { Input, Select } from "antd";

export const Stage2Container = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
`;

export const NameWrapper = styled.div<{ isMobile?: boolean }>`
  display: ${({ isMobile }) => (isMobile ? "block" : "flex")};
  justify-content: space-between;
  align-items: center;
  grid-gap: 10px;
  margin-top: 20px;
`;

export const SectionWrapper = styled.div`
  margin-top: 20px;
`;

export const InputWrapper = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  margin-bottom: ${({ isMobile }) => (isMobile ? "20px" : 0)};
`;

export const InputX = styled(Input)``;

export const Label = styled.div``;

export const Selector = styled(Select)``;

export const PhoneWrapper = styled.div`
  width: 100%;
`;
