const location = window.location.hostname;
const isProd = location === "deals.delphimetals.com";
const isDev = location === "deals-dev.delphimetals.com";

export const SERVER_URL = () => {
  if (isProd) {
    return process.env.REACT_APP_SERVER_URL_PROD;
  } else if (isDev) {
    return process.env.REACT_APP_SERVER_URL_DEV;
  }
  return process.env.REACT_APP_SERVER_URL_LOCAL;
};
