import React from "react";
import { LoadingContainer } from "./Loading.style";
import { Spin } from "antd";

const Loading = () => {
  return (
    <LoadingContainer>
      <Spin tip="Loading" size="large" />
    </LoadingContainer>
  );
};

export default Loading;
