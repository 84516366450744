import React, { FC, useContext, useState } from "react";
import { MainContext } from "../../pages/MainPage/context";
import { Modal } from "antd";
import { useMediaQuery } from "react-responsive";

import DelphiDeals from "../../../Assets/logo/dmd2.png";
import SectionCard from "../../atoms/SectionCard";
import {
  CTA,
  DealContainer,
  DealWrapper,
  DMD,
  DML,
  Line1,
  MobileCarousel,
  OrderCTAWrapper,
  PriceFlash,
  Section,
} from "./DealCard.style";
import { currencySymbolConversion } from "../../pages/MainPage/helpers/MainPage.helpers";
import OrderModal from "../../atoms/OrderModal";

interface DealsCardProps {
  deal: any;
}

const DealsCard: FC<DealsCardProps> = ({ deal }) => {
  const MContext = useContext(MainContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dealMembers = [] } = deal ?? {};

  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  });

  const showModal = () => {
    MContext?.setDealPrice(deal.dealPrice);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChange = (currentSlide: number) => {};

  return (
    <DealContainer>
      <Line1> </Line1>

      <PriceFlash>
        <DML>
          <DMD>
            {" "}
            <img
              src={DelphiDeals}
              alt="Delphi Deals Mini logo"
              width={80}
            />{" "}
          </DMD>
        </DML>

        {currencySymbolConversion(
          deal.dealPrice,
          MContext?.currencyCode ?? "NGN"
        )}
      </PriceFlash>

      {isMobile ? (
        <MobileCarousel afterChange={onChange} autoplay dotPosition="top">
          {dealMembers[0] && (
            <Section
              onClick={() => {
                window.open(dealMembers[0]?.onlineStoreUrl, "_blank");
              }}
            >
              <SectionCard member={dealMembers[0]} dealPrice={deal.dealPrice} />
            </Section>
          )}
          {dealMembers[1] && (
            <Section
              onClick={() => {
                window.open(dealMembers[1]?.onlineStoreUrl, "_blank");
              }}
            >
              <SectionCard member={dealMembers[1]} dealPrice={deal.dealPrice} />
            </Section>
          )}
          {dealMembers[2] && (
            <Section
              onClick={() => {
                window.open(dealMembers[2]?.onlineStoreUrl, "_blank");
              }}
            >
              <SectionCard member={dealMembers[2]} dealPrice={deal.dealPrice} />
            </Section>
          )}
        </MobileCarousel>
      ) : (
        <DealWrapper>
          {dealMembers[0] && (
            <Section
              onClick={() => {
                window.open(dealMembers[0]?.onlineStoreUrl, "_blank");
              }}
            >
              <SectionCard member={dealMembers[0]} dealPrice={deal.dealPrice} />
            </Section>
          )}
          {dealMembers[1] && (
            <Section
              onClick={() => {
                window.open(dealMembers[1]?.onlineStoreUrl, "_blank");
              }}
            >
              <SectionCard member={dealMembers[1]} dealPrice={deal.dealPrice} />
            </Section>
          )}
          {dealMembers[2] && (
            <Section
              onClick={() => {
                window.open(dealMembers[2]?.onlineStoreUrl, "_blank");
              }}
            >
              <SectionCard member={dealMembers[2]} dealPrice={deal.dealPrice} />
            </Section>
          )}
        </DealWrapper>
      )}

      <OrderCTAWrapper>
        <CTA onClick={showModal}>
          {" "}
          Pay{" "}
          {currencySymbolConversion(
            deal.dealPrice,
            MContext?.currencyCode ?? "NGN"
          )}{" "}
          for 1 of these 3
        </CTA>
      </OrderCTAWrapper>
      <Modal
        title="Order Page"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        footer={null}
        destroyOnClose={true}
      >
        <OrderModal deal={deal} onCancel={handleCancel} />
      </Modal>
    </DealContainer>
  );
};

export default DealsCard;
