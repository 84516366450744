const NGNTOUSD = 908.97;
const CEDITOUSD = 11.35;

export const currencySymbolConversion = (price: any, currencyCode: string) => {
  const newPrice = parseInt(price);
  console.log(newPrice);
  switch (currencyCode) {
    case "USD":
    default:
      return `$${numberWithCommas(newPrice)}`;
    case "NGN":
      return `N${numberWithCommas(Math.ceil(newPrice * NGNTOUSD))}`;
    case "GHS":
      return `C${numberWithCommas(Math.ceil(newPrice * CEDITOUSD))}`;
  }
};

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithCommas2(x: number) {
  const x1 = x / 100;
  return x1.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
