import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { AppContainer } from "./App.style";
import MainPage from "./components/pages/MainPage";
import OrderInStore from "./components/pages/OrderInStore";
import PaymentDone from "./components/pages/PaymentDone";
import cookie from "js-cookie";
import Instagram from "./components/pages/Instagram";

function App() {
  cookie.set("source", window.location.href);
  return (
    <AppContainer>
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/:id" element={<MainPage />} />
          <Route path="/paymentdone" element={<PaymentDone />} />
          <Route path="/orderinstore" element={<OrderInStore />} />
          <Route path="/instagram" element={<Instagram />} />
        </Routes>
      </Router>
    </AppContainer>
  );
}

export default App;
