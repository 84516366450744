import styled from "styled-components";

export const FooterWrapper = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  margin-top: 80px;
  text-align: center;

  color: #888888;
`;
