import styled from "styled-components";
import { device } from "../../../devices";

export const MainPageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media ${device.desktop} {
    width: 800px;
  }
`;
