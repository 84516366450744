import React, { FC, useState, useContext } from "react";
import Axios from "axios";
import { useNavigate } from "react-router";
import { OrderContext } from "../OrderModal/OrderModal";
import { MainContext } from "../../pages/MainPage/context";
import Buttons from "../Buttons/Buttons";
import { CTAWrapper } from "../Stage1/Stage1.style";
import PaystackLogo from "./logos/PaystackLogo";
import {
  ImageWrapper,
  MainContainer,
  OptionsWrapper,
  PayInStore,
  PaymentStageContainer,
  PaymentWrapper,
  RadioC,
  RadioD,
} from "./PaymentStage.style";
import useError from "../../../hooks/useError";
import { RadioChangeEvent } from "antd";
import StripeLogo from "./logos/StripeLogo";
import { SERVER_URL } from "../../../helpers/config";

interface PaymentStageProps {
  onCancel: () => void;
}

const PaymentStage: FC<PaymentStageProps> = ({ onCancel }) => {
  const navigate = useNavigate();
  const OContext = useContext(OrderContext);
  const MContext = useContext(MainContext);
  const [error, setError] = useState<any>();
  const [id] = useState<number>(new Date().valueOf());

  useError(error);
  const [selected, setSelected] = useState<string>("");

  const { currencyCode, dealPrice } = MContext ?? {};

  const { details } = OContext ?? {};

  const handleNext = async () => {
    if (selected === "payStack") {
      await Axios.post(`${SERVER_URL()}/paystackpayment`, {
        ...OContext!.details,
        currency: MContext?.currencyCode,
        reference: crypto.randomUUID(),
        id,
      })
        .then((res) => {
          if (res.data.error) {
            setError(res.data.error.message);
          }
          const url = res?.data?.data?.data?.authorization_url;
          window.open(url, "_self");
        })
        .catch((e) => {
          console.log(e);
        });
    }
    if (selected === "inStore") {
      navigate("/orderinstore", {
        state: {
          details,
          currencyCode,
          dealPrice,
        },
      });
    }
    if (selected === "stripe") {
      await Axios.post(
        `${SERVER_URL()}/stripepayment`,
        {
          ...OContext!.details,
          currency: MContext?.currencyCode,
          reference: crypto.randomUUID(),
          id,
        },
        {}
      )
        .then((res) => {
          if (res.data.data.error) {
            setError(res.data.error.message);
          }
          const url = res?.data?.data?.url;
          window.open(url, "_self");
        })
        .catch((e) => {
          console.log(e);
          setError(e.message);
        });
    }
  };

  const changeHandler = (e: RadioChangeEvent) => {
    setSelected(e.target.value);
  };

  const checkSelected = () => {
    switch (selected) {
      case "payStack":
      case "stripe":
      case "inStore":
        return false;
      default:
        return true;
    }
  };

  return (
    <MainContainer>
      <OptionsWrapper>
        {MContext?.currencyCode === "USD" ? (
          <RadioC.Group onChange={changeHandler} value={selected}>
            <PaymentStageContainer>
              <PaymentWrapper>
                <RadioC value="stripe">
                  <ImageWrapper>
                    <StripeLogo />
                  </ImageWrapper>
                </RadioC>
              </PaymentWrapper>
            </PaymentStageContainer>
          </RadioC.Group>
        ) : (
          <RadioC.Group onChange={changeHandler} value={selected}>
            <PaymentStageContainer>
              <PaymentWrapper>
                <RadioC value="payStack">
                  <ImageWrapper>
                    <PaystackLogo />
                  </ImageWrapper>
                </RadioC>
              </PaymentWrapper>
            </PaymentStageContainer>

            <PaymentStageContainer>
              <PaymentWrapper>
                <RadioD value="inStore">
                  <ImageWrapper>
                    <PayInStore> Pay In-Store</PayInStore>
                  </ImageWrapper>
                </RadioD>
              </PaymentWrapper>
            </PaymentStageContainer>
          </RadioC.Group>
        )}
      </OptionsWrapper>
      <CTAWrapper>
        <Buttons text="Close" type="default" onClick={onCancel} />
        <Buttons
          text="Next"
          type="primary"
          onClick={handleNext}
          disabled={checkSelected()}
        />
      </CTAWrapper>
    </MainContainer>
  );
};

export default PaymentStage;
