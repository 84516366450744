interface TransformDataProp {
  getDealAssociations: any[];
  getDeals: any[];
}

export const transformData = (data: TransformDataProp) => {
  const { getDealAssociations = [], getDeals = [] } = data ?? {};
  const result: any[] = [];
  for (let deal of getDealAssociations) {
    result.push({
      dealId: deal.dealId,
      dealMembers: getMembers(deal.dealMembers, getDeals),
      dealPrice: deal.dealPrice,
    });
  }

  return result;
};

const getMembers = (dealMembers: string[], deals: any[]) => {
  const result: any[] = [];
  dealMembers.forEach((dealMember) => {
    let item = deals.find((deal) => deal.itemId === dealMember);
    if (item) {
      result.push(item);
    }
  });

  return result;
};
