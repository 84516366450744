import styled from "styled-components";
import { Radio } from "antd";
import { device } from "../../../devices";

export const MainContainer = styled.div`
  padding-top: 70px;
`;
export const PaymentStageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PaymentWrapper = styled.div`
  border: 1px solid #d6d6d6;
  height: 100px;
  margin-top: 20px;
  /* margin-bottom: 0px; */
  border-radius: 16px;
  width: 290px;
  @media ${device.desktop} {
    width: 320px;
  }
`;

export const RadioC = styled(Radio)`
  margin-left: 30px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RadioD = styled(RadioC)`
  margin-left: 7px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  /* left: -40px; */
`;

export const PayInStore = styled.div`
  font-size: 28px;
  font-weight: bold;
  padding-left: 15px;
`;

export const OptionsWrapper = styled.div`
  text-align: center;
`;
