import styled from "styled-components";

export const PaymentDoneContainer = styled.div`
  text-align: center;
  padding: 20px 24px;
`;
export const Header = styled.div`
  margin-top: 20px;
  font-size: 24px;
  font-weight: bolder;
`;

export const ConfirmedText = styled.div`
  margin-top: 40px;
  font-size: 20px;
  font-weight: 400;
  color: #00b812;
`;

export const OtherText = styled.div`
  margin-top: 20px;
  line-height: 25px;
  margin-bottom: 40px;
`;

export const OrderNumber = styled.span`
  font-weight: bold;
`;

export const CustomerDetails = styled.div`
  margin-top: 20px;
`;
export const CustomerText = styled.div``;
export const Line = styled.hr`
  border: 1px solid #e0e0e0;
`;
export const ImgWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Product = styled.div`
  margin-bottom: 20px;
`;
export const PlacedOn = styled.div`
  margin-top: 20px;
  font-weight: bold;
`;
export const PlacedOn2 = styled.div``;
export const OrderSummaryWrapper = styled.div`
  margin-top: 40px;
  background: #f6f6f6;
  display: inline-block;
  width: 250px;
  padding: 15px 25px;
  padding-bottom: 20px;
  border-radius: 20px;
`;
export const SummaryHeader = styled.div`
  font-weight: bold;
`;
export const SummaryDetailsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* font-weight: bold; */
`;
export const TotalText = styled.div``;
export const Price = styled.div``;

export const BackButtonWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
`;

export const LoadingWrapper = styled.div`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 200px;
`;

export const ConfirmedWrapper = styled.span`
  position: relative;
  top: 5px;
  margin-right: 10px;
`;
