import React, { FC, useState, useContext } from "react";
import { OrderContext } from "../OrderModal/OrderModal";
import * as EmailValidator from "email-validator";
import { useMediaQuery } from "react-responsive";
import { COUNTRIES, PHONE_CODES } from "../../../hooks/helpers/countries";
import Buttons from "../Buttons/Buttons";
import { CTAWrapper } from "../Stage1/Stage1.style";
import {
  InputWrapper,
  InputX,
  Label,
  NameWrapper,
  PhoneWrapper,
  SectionWrapper,
  Selector,
  Stage2Container,
} from "./Stage2.style";

interface Stage2Props {
  onCancel: () => void;
}
interface FormObjType {
  Country?: string;
  "First Name"?: string;
  "Last Name"?: string;
  Address1?: string;
  Address2?: string;
  City?: string;
  State?: string;
  "Phone Number"?: string;
  Email?: string;
}
interface InputComponentProps {
  placeholder?: string;
  labelText: string;
  setFormObj: React.Dispatch<any>;
  formObj: FormObjType;
  isMobile?: boolean;
}

const Stage2: FC<Stage2Props> = ({ onCancel }) => {
  const OContext = useContext(OrderContext);
  const [formObj, setFormObj] = useState<FormObjType | any>({
    Country: "Nigeria",
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 375px)",
  });
  const validateForm = () => {
    const { Country, Address1, City, State, Email } = formObj ?? {};
    if (
      Country &&
      Address1 &&
      City &&
      State &&
      formObj["First Name"] &&
      formObj["Last Name"] &&
      formObj["Phone Number"]
    ) {
      if (EmailValidator.validate(Email)) {
        return false;
      }
    }
    return true;
  };

  const handleNext = () => {
    const { details = {}, setDetails, setStage } = OContext! ?? {};

    setDetails({ ...details, ...formObj });
    setStage(2);
  };

  return (
    <Stage2Container>
      <NameWrapper>
        <InputComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="First Name"
        />
        <InputComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="Last Name"
        />
      </NameWrapper>
      <SectionWrapper>
        <InputComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="Address1"
        />
      </SectionWrapper>
      <SectionWrapper>
        <InputComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="Address2"
        />
      </SectionWrapper>
      <NameWrapper>
        <InputComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="City"
        />
        <InputComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="State"
        />
      </NameWrapper>
      <NameWrapper isMobile={isMobile}>
        <CountryComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="Country"
          isMobile={isMobile}
        />
        <PhoneComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="Phone Number"
        />
      </NameWrapper>
      <SectionWrapper>
        <InputComponent
          formObj={formObj}
          setFormObj={setFormObj}
          labelText="Email"
        />
      </SectionWrapper>
      <CTAWrapper>
        <Buttons text="Close" type="default" onClick={onCancel} />
        <Buttons
          text="Next"
          type="primary"
          onClick={handleNext}
          disabled={validateForm()}
        />
      </CTAWrapper>
    </Stage2Container>
  );
};

const InputComponent: FC<InputComponentProps> = ({
  placeholder,
  labelText,
  setFormObj,
  formObj,
}) => {
  const [value, setValue] = useState("");

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const saveToParentObj = () => {
    setFormObj({ ...formObj, [labelText]: value });
  };
  return (
    <InputWrapper>
      <Label> {labelText}</Label>
      <InputX
        placeholder={placeholder}
        value={value}
        onChange={changeHandler}
        onBlur={saveToParentObj}
      />
    </InputWrapper>
  );
};

const CountryComponent: FC<InputComponentProps> = ({
  labelText,
  setFormObj,
  formObj,
  isMobile,
}) => {
  return (
    <InputWrapper isMobile={isMobile}>
      <Label> {labelText}</Label>
      <Selector
        showSearch
        defaultValue={formObj?.Country}
        style={{ width: isMobile ? 300 : 320 }}
        onChange={(e) => setFormObj({ ...formObj, Country: e })}
        options={COUNTRIES}
      />
    </InputWrapper>
  );
};

const PhoneComponent: FC<InputComponentProps> = ({
  labelText,
  setFormObj,
  formObj,
}) => {
  const country = formObj.Country ?? "";
  const [number, setNumber] = useState(PHONE_CODES[country]);

  const phoneHandler = () => {
    const combinedNumber = PHONE_CODES[country] + number;
    setFormObj({ ...formObj, "Phone Number": combinedNumber });
  };
  return (
    <PhoneWrapper>
      <Label> {labelText} </Label>
      <InputX.Group compact>
        <InputX
          style={{ width: "30%" }}
          defaultValue={PHONE_CODES["Nigeria"]}
          value={PHONE_CODES[country]}
          disabled
        />
        <InputX
          style={{ width: "70%" }}
          defaultValue=""
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          onBlur={phoneHandler}
          type="number"
        />
      </InputX.Group>
    </PhoneWrapper>
  );
};

export default Stage2;
