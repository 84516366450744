import React, { FC, useContext, useRef } from "react";
import { InstagramContext } from "../../pages/Instagram/context";
import DelphiDeals from "../../../Assets/logo/dmd2.png";
import SectionCard from "../../atoms/SectionCard";
import {
  CTA,
  DealContainer,
  DealWrapper,
  DMD,
  DML,
  ExpiresWrapper,
  Line1,
  OrderCTAWrapper,
  PriceFlash,
  Section,
} from "./DealCard.style";
import { currencySymbolConversion } from "../../pages/MainPage/helpers/MainPage.helpers";
import { exportComponentAsJPEG } from "react-component-export-image";

import {
  getSundayOfCurrentWeek,
  isThisInstagram,
} from "../../../helpers/mainPage";

interface DealsCardProps {
  deal: any;
}
const isInstagram = isThisInstagram();
const DealsCardI: FC<DealsCardProps> = ({ deal }) => {
  const MContext = useContext(InstagramContext);
  const { dealMembers = [] } = deal ?? {};
  const refRef = useRef(null);
  const expiredDate = getSundayOfCurrentWeek();

  const downloadImg = (id: string) => {
    exportComponentAsJPEG(refRef, {
      fileName: id,
      html2CanvasOptions: {
        backgroundColor: "#fff",
      },
    });
  };

  return (
    <DealContainer
      isInstagram={isInstagram}
      style={{ height: "950px", cursor: "pointer" }}
      ref={refRef}
      onClick={() => {
        downloadImg(deal.dealId);
      }}
    >
      <Line1 isInstagram={isInstagram}> </Line1>
      <PriceFlash isInsta>
        <DML>
          <DMD>
            {" "}
            <img
              src={DelphiDeals}
              alt="Delphi Deals Mini logo"
              width={80}
            />{" "}
          </DMD>
        </DML>

        {currencySymbolConversion(
          deal.dealPrice,
          MContext?.currencyCode ?? "NGN"
        )}
      </PriceFlash>

      <DealWrapper isInstagram={isInstagram}>
        {dealMembers[0] && (
          <Section isInstagram={isInstagram}>
            <SectionCard member={dealMembers[0]} dealPrice={deal.dealPrice} />
          </Section>
        )}
        {dealMembers[1] && (
          <Section isInstagram={isInstagram}>
            <SectionCard member={dealMembers[1]} dealPrice={deal.dealPrice} />
          </Section>
        )}
        {dealMembers[2] && (
          <Section isInstagram={isInstagram}>
            <SectionCard member={dealMembers[2]} dealPrice={deal.dealPrice} />
          </Section>
        )}
      </DealWrapper>

      <OrderCTAWrapper isInstagram={isInstagram}>
        <CTA isInstagram={isInstagram}>
          {" "}
          Pay{" "}
          {currencySymbolConversion(
            deal.dealPrice,
            MContext?.currencyCode ?? "NGN"
          )}{" "}
          for 1 of these 3
        </CTA>
      </OrderCTAWrapper>

      <ExpiresWrapper>
        <>Deal Expires: {expiredDate} </>
      </ExpiresWrapper>
    </DealContainer>
  );
};

export default DealsCardI;
