import styled from "styled-components";
import { device } from "../../../devices";

export const SectionCardWrapper = styled.div`
  background-color: #f1f2f6;
  margin-left: 35px;
  @media ${device.tablet} {
    margin-left: 0;
  }
`;

export const ImageWrapper = styled.div<{ isInstagram?: boolean }>`
  height: ${({ isInstagram }) => (isInstagram ? "370px" : "260px")};
`;
export const PriceFooter = styled.div<{ isInstagram?: boolean }>`
  width: ${({ isInstagram }) => (isInstagram ? "340px" : "250px")};
  background: #fff;
  margin-top: 0;
  position: relative;
  top: -5px;
  border-top: 2px solid lightgrey;
`;
