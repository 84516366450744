import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { SAVE_RESERVED_DEALS } from "../schemas/schema";

interface ResponseType {
  id: string | undefined;
  loading: boolean;
  error?: any;
}

interface ReservedDealsType {
  reference: string;
  dealId: string;
  itemId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  email: string;
  fname: string;
  lname: string;
  phoneNumber: string;
  image: string;
  onlineStoreUrl: string;
  ringSize: string;
  title: string;
  dealPrice: string;
  itemPrice: string;
  currencyCode: string;
  expiryDate: string;
  orderDate: string;
}

const useSaveReservedDeals = (
  params: ReservedDealsType,
  fetch: boolean
): ResponseType => {
  const [saveReservedDeals, { loading, error, data }] =
    useMutation(SAVE_RESERVED_DEALS);
  useEffect(() => {
    if (fetch) {
      saveReservedDeals({
        variables: {
          params,
        },
      });
    }
  }, [fetch, saveReservedDeals]);

  if (error) {
    return {
      id: undefined,
      loading,
    };
  }

  return {
    id: data?.saveReservedDeal?.id,
    loading,
  };
};

export default useSaveReservedDeals;
