export const resolveCurrencyCodes = (country: string | undefined) => {
  switch (country) {
    case "Nigeria":
      return "NGN";
    case "Ghana":
      return "GHS";
    default:
      return "USD";
  }
};
