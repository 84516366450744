import styled from "styled-components";

export const PaginationWrapper = styled.div`
  text-align: right;
  padding-top: 10px;
`;

export const LoadingWrapper = styled.div`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 200px;
`;
